// Libraries, components, etc. //
import { inject, observer } from "mobx-react";
import React from 'react';
import styled from 'styled-components';
import Avatar from './avatar';
import Icon from '../icons';
import * as vars from '../constants';

//#region Styles
const HeaderComponent = styled.div`
    position: sticky;
	z-index: 40;
    flex-direction: row;
	gap: .5rem;
	top: 0;
	display: flex;
	width: 100%;
	height: ${vars.SIZE_HEADER_HEIGHT};
    background-color: ${vars.COLOR_WHITE};
`;
const HeaderTitle = styled.span`
    flex-direction: row;
	display: flex;
    margin: auto 0;
    font-size: 1.3rem;
    text-align: center;
    color: ${vars.COLOR_TEXT_SECONDARY};
`;
const UserSection = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	flex-direction: row;
	gap: .5rem;
	display: flex;
	height: ${vars.SIZE_HEADER_HEIGHT};
`;
const UserMenu = styled.div`
	position: absolute;
	top: calc(${vars.SIZE_HEADER_HEIGHT} + .5rem);
	flex-direction: column;
	display: flex;
	opacity: ${props => props.visible ? '1' : '0'};
	transition: ease-in .2s visibility, ease-in .2s opacity;
	border: 1px solid ${vars.COLOR_BORDER};
	border-radius: .25rem;
    min-width: 100px;
	width: 100%;
	background-color: ${vars.COLOR_WHITE};
	visibility: ${props => props.visible ? 'visible' : 'hidden'};

	@media ${vars.Device.mobileM} {
		right: 0;
		width: 50vw;
	}

	@media ${vars.Device.laptopS} {
		width: 100%;
	}
`;
const UserMenuItem = styled.span`
	border-top: ${props => props.border ? '1px solid '+vars.COLOR_BORDER : 'none'};
	width: 100%;
	padding: .5rem;
	cursor: pointer;
	text-align: center;

	&:hover {
		background-color: ${vars.COLOR_BORDER};
		color: ${vars.COLOR_WHITE};
	}
`;
//#endregion

class Header extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isHomePage: false,
			menuVisible : false
		}

		this.menuFunction = this.menuFunction.bind(this);
	}
	render() {
		const userData = this.props.store.User.userData;
		const { menuVisible } = this.state;
		const headerText = this.props.title || '';
		const hideButton = this.props.hideButton || false;
		
		return (
			<HeaderComponent>
                <HeaderTitle>{headerText}</HeaderTitle>
				<Icon name='add' width={hideButton ? '0px' :'16px'} color={vars.COLOR_TEXT_SECONDARY} hoverColor={vars.COLOR_PRIMARY} onClick={this.props.onClick}/>
                <UserSection>
					<Avatar firstName={userData.first_name} lastName={userData.last_name} url={userData.profile_image_url} />
					<Icon name='menu' width='16px' color={vars.COLOR_TEXT_SECONDARY} hoverColor={vars.COLOR_TEXT_MAIN} onClick={() => this.menuFunction('visible')}/>
					<UserMenu visible={menuVisible}>
						<UserMenuItem onClick={() => this.menuFunction('profile', userData)}>Профиль</UserMenuItem>
						<UserMenuItem border={true} onClick={() => this.menuFunction('logout')}>Выйти</UserMenuItem>
					</UserMenu>
				</UserSection>
            </HeaderComponent>
		);
	}

	componentDidMount() {
		// if (this.props.location.pathname !== '/' && this.props.store.Main.currentNamespace === null) {
		// 	this.props.history.push('/');
		// }
	}

	menuFunction(_type = 'none', _data) {
		if (_type === 'none')
			return;

		const {
			menuVisible
		} = this.state;

		switch (_type) {
			case 'visible':
				this.setState({
					menuVisible: !menuVisible
				});
				break;
			case 'profile':
				this.props.store.Main.setModal('profile', _data, 'center');
				this.setState({
					menuVisible: false
				});
				break;
			case 'logout':
				this.props.store.User.logout();
				this.setState({
					menuVisible: false
				});
				break;
			default:
				break;
		}
	}
}

export default inject("store")(observer(Header)); 
