import axios from "axios";
import { Config } from "../constants";
import requests from './request';
const NODE_ENV = process.env.NODE_ENV || 'production';

export async function create(data) {
    const response = await requests.create(Config.ITEM, data);
    return response.data;
}

export default { create };