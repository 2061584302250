import React from 'react';
import Logo from './logo';
import LogoText from './logo_text';
import { ReactComponent as CashFile } from "./cash.svg";
import { ReactComponent as CardFile } from "./card.svg";
import { ReactComponent as DepositeFile } from "./bank_deposit.svg";
import { ReactComponent as InvestmentFile } from "./investment.svg";
import Menu from './menu';
import CurrencyRub from './currency_rub';
import CurrencyEur from './currency_eur';
import CurrencyUsd from './currency_usd';
import Card from './card';
import BankDeposit from './bank_deposit';
import Credit from './credit';
import Cash from './cash';
import Investment from './investment';
import ArrowDown from './arrow_down';
import Add from './add';
import ArrowUp from './arrow_up';
import ArrowRight from './arrow_right';
import Delete from './delete';
import styled from 'styled-components';
import { ReactComponent as CalendarFile} from './calendar.svg';
import { ReactComponent as ArrowLeftFile} from './arrow_left.svg';
import { ReactComponent as ArrowRightFile} from './arrow_right.svg';
import { ReactComponent as MoneyFile} from './money.svg';
import SidebarMenu from './sidebar_menu';

const CashIcon = styled(CashFile)`
    fill: ${props => props.fill};
`;
const CardIcon = styled(CardFile)`
    fill: ${props => props.fill};
`;
const DepositeIcon = styled(DepositeFile)`
    fill: ${props => props.fill};
`;
const InvestmentIcon = styled(InvestmentFile)`
    fill: ${props => props.fill};
`;
const CalendarIcon = styled(CalendarFile)`
    fill: ${props => props.fill};
`;
const ArrowLeftIcon = styled(ArrowLeftFile)`
    fill: ${props => props.fill};
`;
const ArrowRightIcon = styled(ArrowRightFile)`
    fill: ${props => props.fill};
`;
const MoneyIcon = styled(MoneyFile)`
    fill: ${props => props.fill};
`;

const Icon = (props) => {
    const name = props.name || '';
    switch(name) {
        case 'logo':
            return <Logo width='16px' height='16px' {...props}/>;
        case 'logo_text':
            return <LogoText width='16px' height='16px' {...props}/>;
        case 'menu':
            return <Menu width='16px' height='16px' {...props}/>;
        case 'currency_rub':
            return <CurrencyRub width='16px' height='16px' {...props}/>;
        case 'currency_eur':
            return <CurrencyEur width='16px' height='16px' {...props}/>;
        case 'currency_usd':
            return <CurrencyUsd width='16px' height='16px' {...props}/>;
        case 'card':
            //return <Card width='16px' height='16px' {...props}/>;
            return <CardIcon width='16px' height='16px' {...props}/>
        case 'bank_deposit':
            return <DepositeIcon width='16px' height='16px' {...props}/>;
        case 'credit':
            return <Credit width='16px' height='16px' {...props}/>;
        case 'cash':
            return <CashIcon width='16px' height='16px' {...props}/>;
        case 'investment':
            return <InvestmentIcon width='16px' height='16px' {...props}/>;
        case 'arrow_down':
            return <ArrowDown width='16px' height='16px' {...props}/>;
        case 'add':
            return <Add width='16px' height='16px' {...props}/>;
        case 'arrow_up':
            return <ArrowUp width='16px' height='16px' {...props}/>;
        case 'arrow_right1':
            return <ArrowRight width='16px' height='16px' {...props}/>;
            case 'arrow_right':
                //return <ArrowRight width='16px' height='16px' {...props}/>;
                return <ArrowRightIcon width='16px' height='16px' {...props}/>;
        case 'arrow_left':
            return <ArrowLeftIcon width='16px' height='16px' {...props}/>;
        case 'calendar':
            return <CalendarIcon width='16px' height='16px' {...props}/>;
        case 'delete':
            return <Delete width='16px' height='16px' {...props}/>;
        case 'sidebar_menu':
            return <SidebarMenu width='16px' height='16px' {...props}/>;
        case 'money':
            return <MoneyIcon width='16px' height='16px' {...props}/>;
        default:
            return null;
    }
}

export default Icon;