// Libraries, components, etc. //
import axios from 'axios';
import { action, makeObservable, observable } from "mobx";
import { toast } from 'react-toastify';
import { config } from '../env';
import { getToken } from '../utils';

const NODE_ENV = process.env.NODE_ENV || 'production';

class TagRelation {
    token = '';
    tagrelations = [];

    constructor() {
        makeObservable(this, {
            token: observable,
            tagrelations: observable,
            create: action,
            delete: action
        });

        this.token = getToken();
        axios.defaults.headers.common['x-user-language'] = 'ru';
        axios.defaults.headers.common['x-app-name'] = 'argent';
        axios.defaults.headers.common['x-access-token'] = this.token;
        this.loadData();

        this.create = this.create.bind(this);
        this.delete = this.delete.bind(this);
    }

    loadData() {
        axios.get(config.api.host_andtask[NODE_ENV]+config.api.tag_relation.getall)
        .then(result => {
            if (result.data.status) {
                this.tagrelations = result.data.data;
            }
            else {
                toast.error(result.data.text);
            }
        })
    }

    create(_data) {
        const data = {
            object_id: _data.object_id,
            type: _data.type,
            tag_id: _data.tag_id
        };

        axios.post(config.api.host_andtask[NODE_ENV] + config.api.tag_relation.create, data)
        .then(result => {
            if (result.data.status) {
                this.loadData();
            }
            else {
                toast.error(result.data.text);
            }
        })
        .catch(error => {
            toast.error(error.response.data.text);
        })
    }

    delete(_id) {
        axios.delete(config.api.host_andtask[NODE_ENV] + config.api.tag_relation.delete + _id)
        .then(result => {
            if (result.data.status) {
                this.loadData();
            }
            else
                toast.error(result.data.text);
        })
        .catch(error => {
            toast.error(error.response.data.text);
        })
    }
}

export default new TagRelation();