import SVG from '../components/icon';

export default function CurrencyUsd (props) {
    return (
        <SVG
            width={props.width}
            height={props.height === undefined || props.height === null ? props.width : props.height}
            style={{margin: 'auto 0'}}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 512 512"
            {...props}
        >
<g>
	<g>
		<path d="M356.119,342.225l-95.193-101.553V96.124l96.355,0.251h0.01c5.916,0,10.718-4.793,10.718-10.709
			c0-5.904-4.782-10.692-10.679-10.702v-0.005l-96.404-0.256V10.709C260.926,4.793,256.128,0,250.213,0
			c-5.911,0-10.704,4.793-10.704,10.709v63.939l-32.096-0.079c-0.137,0-0.275-0.005-0.408-0.005
			c-32.804,0-55.368,10.358-63.556,29.192c-8.223,18.908-0.311,42.592,22.277,66.686l73.782,78.699v167.805h-96.375
			c-5.915,0-10.708,4.802-10.708,10.718c0,5.916,4.793,10.718,10.708,10.718h96.375v64.9c0,5.914,4.793,10.718,10.704,10.718
			c5.915,0,10.712-4.804,10.712-10.718v-64.9h53.541c33.021,0,55.719-10.451,63.945-29.417
			C386.63,390.018,378.707,366.299,356.119,342.225z M239.509,217.833l-58.157-62.04c-15.905-16.964-22.726-33.224-18.257-43.499
			c4.439-10.225,20.846-16.313,43.921-16.313c0.108,0,0.227,0,0.339,0l32.154,0.089V217.833z M358.758,400.461
			c-4.479,10.323-21.043,16.485-44.291,16.485h-53.541V271.98l79.574,84.889C356.405,373.828,363.227,390.125,358.758,400.461z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
        </SVG>
    );
}