// Libraries, components, etc. //
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Avatar from './avatar';
import Icon from '../icons';
import * as vars from '../constants';
import { Paths, Modals, Device } from "../constants";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { mainStore } from "../store/main";

//#region Styles
const HeaderComponent = styled.div`
    position: sticky;
	z-index: 40;
    flex-direction: row;
	gap: .75rem;
	top: 0;
	display: flex;
	align-items: center;
	width: 100%;
	height: ${vars.SIZE_HEADER_HEIGHT};
	transform: ${props => props.visible ? 'translateX(0)' : 'translateX(100vw)'};
	transition: /*width ease-in .3s, visibility ease-in .1s,*/ transform ease-in .3s;

	@media ${Device.mobileM} {
		transform: ${props => props.visible ? 'translateX(calc(0vw - 20px - 2rem))' : 'translateX(0)'};
	}

	@media ${Device.tablet} {
		transform: none;
	} 
`;
const Title = styled.span`
    flex-direction: row;
	display: flex;
    margin: auto 0;
    font-size: 1.3rem;
    text-align: center;
    color: white;
`;
const UserSection = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	flex-direction: row;
	gap: .5rem;
	display: flex;
	height: ${vars.SIZE_HEADER_HEIGHT};
`;
const UserMenu = styled.div`
	position: absolute;
	top: calc(${vars.SIZE_HEADER_HEIGHT} + .5rem);
	flex-direction: column;
	display: flex;
	opacity: ${props => props.visible ? '1' : '0'};
	transition: ease-in .2s visibility, ease-in .2s opacity;
	border: 1px solid ${vars.COLOR_BORDER};
	border-radius: .25rem;
    min-width: 100px;
	width: 100%;
	background-color: ${vars.COLOR_WHITE};
	visibility: ${props => props.visible ? 'visible' : 'hidden'};

	@media ${vars.Device.mobileM} {
		right: 0;
		width: 50vw;
	}

	@media ${vars.Device.laptopS} {
		width: 100%;
	}
`;
const UserMenuItem = styled.span`
	border-top: ${props => props.border ? '1px solid '+vars.COLOR_BORDER : 'none'};
	width: 100%;
	padding: .5rem;
	cursor: pointer;
	text-align: center;

	&:hover {
		background-color: ${vars.COLOR_BORDER};
		color: ${vars.COLOR_WHITE};
	}
`;
//#endregion

function Header(props) {
	const [isHomePage, setIsHomePage] = useState(false);
	const [menuVisible, setMenuVisible] = useState(false);
	const {pathname} = useLocation();
		const userData = props.store.User.userData;
		const headerText = props.title || '';
		const [headerTitle, setHeaderTitle] = useState('');
		const hideButton = props.hideButton || false;
		const [addModalName, setAddModalName] = useState('');
		const [modalData, setModalData] = useState(null);
		
	useEffect(() => {
		switch (pathname) {
			case Paths.accounts:
				setHeaderTitle('Счета');
				setAddModalName(Modals.account.name);
				setModalData(null);
				break;
			case Paths.counterparties:
				setHeaderTitle('Организации');
				setAddModalName(Modals.conterparty.name);
				setModalData(null);
				break;
			case Paths.items:
				setHeaderTitle('Товары');
				setAddModalName(Modals.item.name);
				setModalData(null);
				break;
			case Paths.tags:
				setHeaderTitle('Теги');
				setAddModalName(Modals.tag.name);
				setModalData({id: 0, parent_id: null});
				break;
			case Paths.transactions:
				setHeaderTitle('Операции');
				setAddModalName(Modals.transaction.name);
				setModalData(null);
				break;
			default:
				setHeaderTitle('Главная');
				setAddModalName('');
				setModalData(null);
				break;
		}
	}, [pathname])

	function	menuFunction(_type = 'none', _data) {
			if (_type === 'none')
				return;
	
	
			switch (_type) {
				case 'visible':
					setMenuVisible(!menuVisible);
					break;
				case 'profile':
					mainStore.setModal('profile', _data, 'center');
					setMenuVisible(false);
					break;
				case 'logout':
					props.store.User.logout();
					setMenuVisible(false);
					break;
				default:
					break;
			}
		}

		return (
			<HeaderComponent visible={mainStore.sidebarVisible}>
				<Icon name='sidebar_menu' visible={mainStore.sidebarVisible} onClick={() => mainStore.setSidebarVisible(!mainStore.sidebarVisible)}/>
                <Title>{headerTitle}</Title>
				<Icon name='add' width={hideButton ? '0px' :'20px'} color='white' hoverColor={vars.COLOR_PRIMARY} onClick={() => mainStore.setModal(addModalName, modalData)}/>
                <UserSection>
					<Avatar placeholder={userData.placeholder} url={userData.profile_image_url} />
					<Icon name='menu' width='16px' color='white' hoverColor={vars.COLOR_TEXT_MAIN} onClick={() => menuFunction('visible')}/>
					<UserMenu visible={menuVisible}>
						<UserMenuItem onClick={() => menuFunction('profile', userData)}>Профиль</UserMenuItem>
						<UserMenuItem border={true} onClick={() => menuFunction('logout')}>Выйти</UserMenuItem>
					</UserMenu>
				</UserSection>
            </HeaderComponent>
		);

	
}

export default inject("store")(observer(Header)); 
