import styled from "styled-components";
import * as vars from '../../constants';

const TagBadge = styled.span`
    border-radius: .25rem;
    padding: .25rem .5rem;
    background-color: ${vars.COLOR_PRIMARY};
    cursor: ${props => props.changeCursor ? 'not-allowed' : 'default'};
    font-size: 0.75rem;
    color: ${vars.COLOR_WHITE};
`;

export default TagBadge;