import styled from "styled-components";
import * as vars from '../../constants';
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Modals } from "../../constants";
import { mainStore } from "../../store/main";
import useIsMobile from "../../hooks/useIsMobile";
import AccountInfo from "./accountInfo";
import TransactionType from "./transactionType";
import TagBadge from "../../shared/styles/TagBadge";

const Component = styled.div`
    flex-direction: column;
    display: flex;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    padding-bottom: .25rem;

    &:hover {
        background-color: rgb(61,204,74,0.1);
    }

    &:nth-last-child(1) {
        border-bottom: none;
    }
`;

const TransactionData = styled.div`
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: .5rem;
    display: flex;
    width: 100%;
    min-height: 1.75rem;
    padding: .25rem .5rem;

    @media ${vars.Device.mobileS} {
        flex-direction: column;
    }

    @media ${vars.Device.tablet} {
        flex-direction: row;
    }
`;
const TransactionItem = styled.span`
    display: flex;
    justify-content: ${props => props.align || 'center'};
    align-items: center;
    gap: .25rem;
    flex: ${props => props.flex !== undefined ? props.flex : '1'};
    width: 100%;
    text-align: ${props => props.align || 'left'};
    min-height: 1.75rem;
`;

const TagBadges = ({tags}) => {
    return <TransactionData>
        {tags.map(tag => <TagBadge>{tag}</TagBadge>)}
    </TransactionData>
}

export default function Item({data}) {

    const isMobile = useIsMobile();
    const [tags, setTags] = useState([]);

    useEffect(() => {
        if (data.positions.length === 0) {
            return;
        }

        const unique = new Set();
        data.positions.forEach(element => {
            element.tags.forEach(tag => {
                unique.add(tag.name);
            })
        });

        setTags([...unique]);
    }, [data.positions])


    const handleClick = () => {
        mainStore.setModal(Modals.transaction.name, data);
    }

    return <Component onClick={handleClick} >
        <TransactionData>
            <TransactionItem align='start'>{data.name}</TransactionItem>
            <TransactionType type={data.transaction_type} />
            <TransactionItem flex='2' align={isMobile ? 'start' : 'center'}>
                <AccountInfo
                    account={data.account}
                    accountDestination={data.accountDestination} />
            </TransactionItem>
            <TransactionItem align={isMobile ? 'start' : 'center'}>
                {data.conterparty !== null ? data.conterparty.name : '-'}
            </TransactionItem>
            <TransactionItem align={isMobile ? 'start' : 'end'}>
                {data.total_amount.toFixed(2)}
            </TransactionItem>
        </TransactionData>
        { tags.length > 0 && <TagBadges tags={tags}/> }
    </Component>

}
