// Libraries, components, etc. //
import { Provider } from "mobx-react";
import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot} from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import { createGlobalStyle } from 'styled-components';
import AccountType from './store/account_type';
import Account from './store/account';
import App from './App';
import ConterpartyAddress from './store/conterparty_address';
import Conterparty from './store/conterparty';
import Item from './store/item';
import { mainStore as Main } from './store/main';
import Position from './store/position';
import TagRelation from './store/tag_relation';
import Tag from './store/tag';
import Transaction from './store/transaction';
import User from './store/user';
import { dataStore } from './store/data';

const GlobalStyle = createGlobalStyle`
	body {
		margin: 0;
		width: 100vw;
    	height: 100vh;
		font-family: 'Roboto', sans-serif;
		-webkit-font-smoothing: antialiased;
  		-moz-osx-font-smoothing: grayscale;

		*, ::before, ::after {
			box-sizing: border-box;
		}
	}

	::-webkit-scrollbar {
		width: .5rem;
    }

	::-webkit-scrollbar-thumb {
		border-radius: .25rem;
		border-width: 1px 1px 1px 2px;
		border-color: rgb(119, 119, 119);
		background-color: rgb(170, 170, 170);
	}

	::-webkit-scrollbar-track {
    	border-width: 0px;
	}
`;

const store = {
	Main,
	User,
	Account,
	AccountType,
	ConterpartyAddress,
	Conterparty,
	Item,
	Position,
	TagRelation,
	Tag,
	Transaction,
	dataStore
}

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Provider store={store}>
	<Router>
		<GlobalStyle />
		<App/>
	</Router>
</Provider>);