import axios from "axios";
import { Config } from "../constants";
import requests from './request';
const NODE_ENV = process.env.NODE_ENV || 'production';


export async function create(position) {
    const response = await requests.create(Config.POSITION, position);
    return response.data;
}

export function createR(position) {
    return requests.create(Config.POSITION, position);
}

export async function update(position) {
    const response = await requests.update(Config.POSITION, position);
    return response.data;
}

export async function remove(id) {
    const response = await requests.remove(Config.POSITION, id);
    return response.data;
}

export default { create, createR, update, remove };