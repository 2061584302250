// Libraries, components, etc. //
import { observer } from "mobx-react";
import React from 'react';
import styled from 'styled-components';
import { getCurrencySymbol } from '../utils'; 
import * as vars from '../constants';
import { dataStore } from "../store/data";
import { mainStore } from "../store/main";

//#region Styles
const Page = styled.div`
	flex-direction: column;
	flex: 1;
	display: flex;
	border: 1px solid ${vars.COLOR_BORDER};
	border-radius: 1rem;
	padding: 0 1rem;
	background-color: ${vars.COLOR_WHITE};
`;
const AccountList = styled.div`
	flex-wrap: wrap;
	flex-direction: row;
	gap: 1rem;
	display: flex;
	padding: .5rem 0;
`;
const AccountItem = styled.div`
	flex-direction: column;
	gap: .25rem;
	display: flex;
	border: 1px dashed ${props => props.isActive ? vars.COLOR_SUCCESS : vars.COLOR_DANGER};
	border-radius: .5rem;
	padding: .5rem;
	cursor: pointer;

	&:hover {
		border: 1px dashed ${vars.COLOR_INFO};
	}
`;
const Type = styled.span`
	font-size: 0.8rem;
	color: ${vars.COLOR_TEXT_SECONDARY};
`;
const Amount = styled.div`
	display: flex;
	font-size: 1rem;
`;
const AmountValue = styled.span`
	display: flex;
	margin: auto 0;
	color: ${props => props.value === 0 ? vars.COLOR_TEXT_SECONDARY : props.value > 0 ? vars.COLOR_SUCCESS : vars.COLOR_DANGER};
`;
//#endregion

function Account(props) {
	const accounts = dataStore.accounts;
	const sidebarVisible = mainStore.sidebarVisible;

	return (
		<Page visible={sidebarVisible}>
			<AccountList>
			{
				accounts.map(account => 
					<AccountItem isActive={account.is_active} onClick={() => mainStore.setModal('account', account)}>
						<span>{account.name}</span>
						<Type>{account.account_type.name_ru}</Type>
						<Amount>
							<AmountValue value={account.amount || 0}>{(account.amount || 0) < 0 ? '-' : ''}{getCurrencySymbol(account.currency)}{Math.abs(account.amount || 0).toFixed(2)}</AmountValue>
						</Amount>
					</AccountItem>	
				)
			}
			</AccountList>
		</Page>
	);
}

export default observer(Account);
