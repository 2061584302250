// Libraries, components, etc. //
import { inject, observer } from "mobx-react";
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import styled, {keyframes} from 'styled-components';
import Button from '../components/button';
import Input from '../components/input';
import * as vars from '../constants';
import { Device } from "../constants";

//#region Styles
// Animations
const gradient = keyframes`
  	0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
`;
// Styles //
const Page = styled.div`
	display: flex;
	animation: ${gradient} 15s ease infinite;
	width: 100vw;
	height: 100vh;
	background: linear-gradient(-45deg, ${vars.COLOR_PRIMARY}, ${vars.COLOR_SECONDARY});
    background-size: 400% 400%;
`;
const Modal = styled.div`
	flex-direction: column;
	display: flex;
	transition: all 1s ease;
	margin: auto;
	border-radius: 0.5rem;
	width: 50vw;
	height: ${props => props.isSignUp ? '60vh' : '50vh'};
	background-color: rgba(255,255,255,0.7);

	@media ${Device.mobileM} {
		width: 80vw;
	}

	@media ${Device.tablet} {
		width: 50vw;
	}
`;
const Title = styled.span`
	display: inline-block;
	width: 100%;
	padding: 0.5rem;
	font-size: 2.5rem;
	font-weight: 200;
	text-align: center;

	@media ${Device.mobileM} {
		font-size: 2rem;
	}

	@media ${Device.tablet} {
		font-size: 2.5rem;
	}
`;
const Form = styled.div`
	flex-direction: column;
	justify-content: center;
	gap: 0.5rem;
	display: flex;
	margin: auto;
	width: 50%;
	padding: 0.5rem;

	@media ${Device.mobileM} {
		width: 80%;
	}

	@media ${Device.tablet} {
		width: 50%;
	}
`;
const ButtonGroup = styled.div`
	flex-direction: column;
	justify-content: center;
	gap: 1rem;
	display: flex;
	margin-top: 10%;
	text-align: center;
	color: ${vars.COLOR_TEXT_SECONDARY};
`;
const GradientTextButton = styled.a`
	color: ${vars.COLOR_TEXT_SECONDARY};
	user-select: none;

	&:hover {
		background: linear-gradient(-45deg, ${vars.COLOR_PRIMARY}, ${vars.COLOR_SECONDARY});
    	-webkit-background-clip: text;
    	-webkit-text-fill-color: transparent;
		cursor: pointer;
	}
`;
const AdditionalFields = styled.div`
	flex-direction: column;
	gap: 0.5rem;
	display: flex;
	overflow: hidden;
	transition: all 1s ease;
	opacity: ${props => props.isSignUp ? '1' : '0'};
	max-height: ${props => props.isSignUp ? '350px' : '0px'};
	padding-bottom: 0.5rem;
`;
//#endregion

function Auth(props) {
	const [userName, setUserName] = useState('');
	const [password, setPassword] = useState('');
	const [email, setEmail] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const isSignUp = !props.store.User.isSignIn;

	function authFunctions(type = 'none') {
		if (type === 'none')
			return;

		if (userName === null || userName === '') {
			toast.error('Логин не может быть пустым.');
			return;
		}

		if (password === null || password === '') {
			toast.error('Пароль не может быть пустым.');
			return;
		}

		if (type === 'signUp') {
			if (email === null || email === '') {
				toast.error('E-mail не может быть пустым.');
				return;
			}

			if (firstName === null || firstName === '') {
				toast.error('Имя не может быть пустым.');
				return;
			}

			if (lastName === null || lastName === '') {
				toast.error('Фамилия не может быть пустой.');
				return;
			}
		}

		const data = {
			user_name: userName,
			password: password,
			email: email,
			first_name: firstName,
			last_name: lastName
		};

		switch (type) {
			case 'signIn':
				props.store.User.signIn(data);
				break;
			case 'signUp':
				props.store.User.signUp(data);
				break;
			default:
				break;
		}
	}

	return (
		<Page>
			<Modal isSignUp={isSignUp}>
				<Title>{isSignUp ? 'Регистрация' : 'Авторизация'}</Title>
				<Form>
					<Input type='text' id='userName' label='Логин' value={userName} onChange={(event) => setUserName(event.target.value)}/>
					<Input type='password' id='password' label='Пароль' value={password} onChange={(event) => setPassword(event.target.value)}/>
					<AdditionalFields isSignUp={isSignUp}>
						<Input type='email' id='email' label='Email' value={email} onChange={(event) => setEmail(event.target.value)}/>
						<Input type='text' id='firstName' label='Имя' value={firstName} onChange={(event) => setFirstName(event.target.value)}/>
						<Input type='text' id='lastName' label='Фамилия' value={lastName} onChange={(event) => setLastName(event.target.value)}/>
					</AdditionalFields>
					<ButtonGroup>
						<Button onClick={() => authFunctions(isSignUp ? 'signUp' : 'signIn')}>{isSignUp ? 'Зарегистрироваться' : 'Войти'}</Button>
						<span>Или все-таки <GradientTextButton onClick={() => props.store.User.setState(isSignUp)}>{isSignUp ? 'зарегистрирован?' : 'регистрация?'}</GradientTextButton></span>
					</ButtonGroup>
				</Form>
			</Modal>
		</Page>
	);
}

export default inject("store")(observer(Auth));
