// Libraries, components, etc. //
import React from 'react';
import styled, { css } from 'styled-components';
import * as vars from '../constants';

//#region Styles
const ButtonStyle = styled.button`
    transition: ease-in 0.3s color, ease-in 0.3s background, ease-in 0.3s border;
    border: ${props => props.borderWidth} solid ${props => props.borderColor};
    border-radius: 0.25rem;
    padding: 0.35rem 0.5rem;
    background: ${props => props.backgroundColor};
    font-size: ${props => props.size};
    font-weight: 400;
    color: ${props => props.color};
    outline: none;
    cursor: pointer;

    ${props => !props.disabled && css`
        &:hover {
            border: ${props => props.borderWidth} solid ${props => props.borderColorHover};
            background: ${props => props.backgroundColorHover};
            color: ${props => props.colorHover};
        }
    `};
`;
const CloseButton = styled.div`
    position: absolute;
    z-index: 50;
    top: 0;
    right: 0;
    display: flex;
    width: 40px;
    height: 40px;
`;
const CloseButtonX = styled.span`
    transition: ease-in 0.2s color;
    cursor: pointer;
    margin: auto;
    font-size: 1.1rem;
    font-weight: bolder;

    &:hover {
        color: ${vars.COLOR_DANGER}
        
    }
`;
//#endregion

const Button = (props) => {
    const type = props.type || '';
    const fill = props.fill;
    const size = props.size || '1rem';
    const disabled = props.disabled;

    switch(type) {
        case 'close':
            return <CloseButton onClick={props.onClick}><CloseButtonX>X</CloseButtonX></CloseButton>;
        case 'danger':
            return <ButtonStyle
                        disabled={disabled}
                        borderColor={getColor('border', disabled, fill, 'transparent', vars.COLOR_DANGER)}
                        borderColorHover={getColor('border', disabled, fill, vars.COLOR_DANGER, 'transparent')}
                        borderWidth='1px'
                        backgroundColor={getColor('background', disabled, fill, vars.COLOR_DANGER, 'transparent')}
                        backgroundColorHover={getColor('background', disabled, fill, 'transparent', vars.COLOR_DANGER)}
                        color={getColor('color', disabled, fill, vars.COLOR_WHITE, vars.COLOR_DANGER)}
                        colorHover={getColor('color', disabled, fill, vars.COLOR_DANGER, vars.COLOR_WHITE)}
                        size={size}
                        onClick={props.onClick}>{props.children}</ButtonStyle>;
        case 'success':
            return <ButtonStyle
                        disabled={disabled}
                        borderColor={getColor('border', disabled, fill, 'transparent', vars.COLOR_SUCCESS)}
                        borderColorHover={getColor('border', disabled, fill, vars.COLOR_SUCCESS, 'transparent')}
                        borderWidth='1px'
                        backgroundColor={getColor('background', disabled, fill, vars.COLOR_SUCCESS, 'transparent')}
                        backgroundColorHover={getColor('background', disabled, fill, 'transparent', vars.COLOR_SUCCESS)}
                        color={getColor('color', disabled, fill, vars.COLOR_WHITE, vars.COLOR_SUCCESS)}
                        colorHover={getColor('color', disabled, fill, vars.COLOR_SUCCESS, vars.COLOR_WHITE)}
                        size={size}
                        onClick={props.onClick}>{props.children}</ButtonStyle>;
        case 'grey':
            return <ButtonStyle
                        disabled={disabled}
                        borderColor={getColor('border', disabled, fill, 'transparent', vars.COLOR_TEXT_SECONDARY)}
                        borderColorHover={getColor('border', disabled, fill, vars.COLOR_TEXT_SECONDARY, 'transparent')}
                        borderWidth='1px'
                        backgroundColor={getColor('background', disabled, fill, vars.COLOR_TEXT_SECONDARY, 'transparent')}
                        backgroundColorHover={getColor('background', disabled, fill, 'transparent', vars.COLOR_TEXT_SECONDARY)}
                        color={getColor('color', disabled, fill, vars.COLOR_WHITE, vars.COLOR_TEXT_SECONDARY)}
                        colorHover={getColor('color', disabled, fill, vars.COLOR_TEXT_SECONDARY, vars.COLOR_WHITE)}
                        size={size}
                        onClick={props.onClick}>{props.children}</ButtonStyle>;
        default:
            return <ButtonStyle
                        disabled={disabled}
                        borderColor='transparent'
                        borderColorHover='transparent'
                        borderWidth='0px'
                        backgroundColor={disabled ? vars.COLOR_GRAY : 'linear-gradient(-45deg, #00b09b, #96c93d)'}
                        backgroundColorHover={disabled ? vars.COLOR_GRAY : 'linear-gradient(-45deg, #04ceb6, #a7de46)'}
                        color='white'
                        colorHover='white'
                        size={size}
                        onClick={props.onClick}>{props.children}</ButtonStyle>;
    }
}

function getColor(_type, _isDisabled, _isFilled, _trueColor, _falseColor) {
    if (!_isDisabled)
        return _isFilled ? _trueColor : _falseColor;
    
    switch (_type) {
        case 'color':
            return _isFilled ? vars.COLOR_WHITE : vars.COLOR_GRAY; 
        case 'background':
            return _isFilled ? vars.COLOR_GRAY : _falseColor;
        default:
            return vars.COLOR_GRAY;
    }
}

export default Button;