// Colors //
export const COLOR_BORDER = '#cccccc';
export const COLOR_BACKGROUND = '#fafafa';
export const COLOR_TEXT_MAIN = '#131516';
export const COLOR_TEXT_SECONDARY = '#6c6c6c';
export const COLOR_PRIMARY = '#00b09b';
export const COLOR_SECONDARY = '#96c93d';
export const COLOR_SUCCESS = '#34B54E';
export const COLOR_INFO = '#1670F7';
export const COLOR_WARNING = '#FFE70F';
export const COLOR_DANGER = '#FF3A4B';
export const COLOR_WHITE = 'white';
export const COLOR_GRAY = '#cccccc';

export const Colors = {
	border: '#cccccc',
	background: '#fafafa',
	text_main: '#131516',
	text_secondary: '#6c6c6c',
	primary: '#00b09b',
	secondary: '#96c93d',
	success: '#34B54E',
	info: '#1670F7',
	warning: '#FFE70F',
	danger: '#FF3A4B',
	white: 'white',
	gray: '#cccccc'
}

export const Modals = {
	account: {
		label: 'Счёт',
		name: 'account'
	},
	conterparty: {
		label: 'Организация',
		name: 'conterparty'
	},
	item: {
		label: 'Товар',
		name: 'item'
	},
	profile: {
		label: 'Профиль',
		name: 'profile'
	},
	tag: {
		label: 'Тег',
		name: 'tag'
	},
	transaction: {
		label: 'Операция',
		name: 'transaction'
	}
}

export const Paths = {
	main: '/',
	accounts: '/accounts',
	counterparties: '/conterparties',
	tags: '/tags',
	transactions: '/transactions',
	items: '/items'
}

// Sizes //
export const SIZE_HEADER_HEIGHT = '50px';
export const BREAKPOINTS = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptopS: '1024px',
    laptopM: '1200px',
    laptopL: '1440px',
    desktop: '2560px'
};
export const Device = {
    mobileS: `(min-width: ${BREAKPOINTS.mobileS})`,
    mobileM: `(min-width: ${BREAKPOINTS.mobileM})`,
    mobileL: `(min-width: ${BREAKPOINTS.mobileL})`,
    tablet: `(min-width: ${BREAKPOINTS.tablet})`,
    laptopS: `(min-width: ${BREAKPOINTS.laptopS})`,
    laptopM: `(min-width: ${BREAKPOINTS.laptopM})`,
    laptopL: `(min-width: ${BREAKPOINTS.laptopL})`,
    desktop: `(min-width: ${BREAKPOINTS.desktop})`
  };

export const IconsNames = [
	'',
	'card',
	'card',
	'bank_deposit',
	'bank_deposit',
	'credit',
	'investment',
	'investment',
	'cash'
]

export const Config = {
	ACCOUNTTYPE: 'account_type',
	ACCOUNT: 'account',
	CONTERPARTY: 'conterparty',
	CONTERPARTYADDRESS: 'conterparty_address',
	ITEM: 'item',
	POSITION: 'position',
	TAG: 'tag',
	TRANSACTION: 'transaction',
	TAGRELATION: 'tag_relation',
	env: process.env.NODE_ENV || 'production',
    api: {
        host: {
            common: {
                development: 'http://localhost:8100/',
                production: 'https://tsvetkov.one:8100/'
            },
            argent: {
                development: 'http://localhost:8102/',
                production: 'https://argent.tsvetkov.one:8102/'
            }
        },
        common: {
            sign_up: "api/auth/signup",
            sign_in: "api/auth/signin",
            get: "api/user",
            update: "api/user",
            change_profile_image: "api/changeprofileimage"
        },
		account_type: "api/accounttypes",
        account: {
            getall: "api/accounts",
            create: "api/account",
            update: "api/account",
            delete: "api/account/"
        },
        conterparty: {
            getall: "api/conterparties",
            create: "api/conterparty",
            update: "api/conterparty",
            delete: "api/conterparty/"
        },
        conterparty_address: {
            getall: "api/conterpartyaddresses",
            create: "api/conterpartyaddress",
            update: "api/conterpartyaddress",
            delete: "api/conterpartyaddress/"
        },
        item: {
            getall: "api/items",
            create: "api/item",
            update: "api/item",
            delete: "api/item/"
        },
        position: {
            getall: "api/positions",
            create: "api/position",
            update: "api/position",
            delete: "api/position/",
        }, 
        tag: {
            getall: "api/tags",
            create: "api/tag",
            update: "api/tag",
            delete: "api/tag/",
            getsuggestiontag: "api/getsuggestiontag/"
        }, 
        transaction: {
            getall: "api/transactions",
            create: "api/transaction",
            update: "api/transaction",
            delete: "api/transaction/",
        },
        tag_relation: {
            getall: "api/tagrelations",
            create: "api/tagrelation",
            update: "api/tagrelation",
            delete: "api/tagrelation/",
        }
	}
}