import { makeObservable, observable, action, makeAutoObservable, computed } from "mobx";
import axios from 'axios';
import { getToken } from '../utils';
import { Config } from '../constants';
import { toast } from 'react-toastify';
import { getAll as accountGetAll } from "../adapters/account";
const NODE_ENV = process.env.NODE_ENV || 'production';



class Data {

    accountTypes = [];
    accounts = [];
    conterparties = [];
    conterpartyAddresses = [];
    items = [];
    positions = [];
    tags = [];
    tagRelations = [];
    transactions = [];

    constructor() {
        makeObservable(this, {
            accountTypes: observable,
            accounts: observable,
            conterparties: observable,
            conterpartyAddresses: observable,
            items: observable,
            positions: observable,
            tags: observable,
            tagRelations: observable,
            transactions: observable,
            fetch: action
        });

        //this.fetch();
    }

    async fetch() {
        let accountTypesResponse,
            accountsResponse,
            conterpartiesResponse,
            conterpartyAddressesResponse,
            itemsResponse,
            positionsResponse,
            tagsResponse,
            tagRelationsResponse,
            transactionsResponse;

        try {
            [   accountTypesResponse,
                accountsResponse,
                conterpartiesResponse,
                conterpartyAddressesResponse,
                itemsResponse,
                positionsResponse,
                tagsResponse,
                tagRelationsResponse,
                transactionsResponse
            ] = await Promise.all([
                axios.get(Config.api.host.argent[NODE_ENV]+Config.api.account_type),
                accountGetAll(),
                axios.get(Config.api.host.argent[NODE_ENV]+Config.api.conterparty.getall),
                axios.get(Config.api.host.argent[NODE_ENV]+Config.api.conterparty_address.getall),
                axios.get(Config.api.host.argent[NODE_ENV]+Config.api.item.getall),
                axios.get(Config.api.host.argent[NODE_ENV]+Config.api.position.getall),
                axios.get(Config.api.host.argent[NODE_ENV]+Config.api.tag.getall),
                axios.get(Config.api.host.argent[NODE_ENV]+Config.api.tag_relation.getall),
                axios.get(Config.api.host.argent[NODE_ENV]+Config.api.transaction.getall)
            ]);
        }
        catch (error) {
            toast.error(error?.response?.data?.message || error.message);
            return;
        }

        let accountTypes = [...this.getAccountTypes(accountTypesResponse.data.data)];
        let accounts = [...this.getAccounts(accountTypes, accountsResponse.data.data)];
        let conterparties = [...this.getConterparties(conterpartiesResponse.data.data,conterpartyAddressesResponse.data.data)];
        let items = [...this.getItems(itemsResponse.data.data)];
        let positions = [...this.getPositions(items, positionsResponse.data.data)];
        let tags = [...this.getTags(tagsResponse.data.data)];
        let tagRelations = [...this.getTagRelations(tags, tagRelationsResponse.data.data)];
        let transactions = [...this.getTransactions(
            accounts,
            conterparties,
            positions,
            tags,
            tagRelations,
            transactionsResponse.data.data, 
        )];

        // calculate amount
        accounts.forEach(a => {
            let amount = 0;
            [...transactions.filter(t => t.account_id === a.id || t.account_destination_id === a.id)].forEach(t => {
                if (t.transaction_type === 'income' || t.transaction_type === 'expense') {
                    amount += t.total_amount;
                }
                else if (t.transaction_type === 'transfer') {
                    if (t.account_id === a.id) {
                        amount -= t.total_amount;
                    }
                    else {
                        amount += t.total_amount;
                    }
                }
            });
            a.amount = amount;
        });
        
        this.accountTypes = accountTypes;
        this.accounts = accounts;
        this.conterparties = conterparties;
        //this.conterpartyAddresses = conterpartyAddresses;
        this.items = items;
        this.positions = positions;
        this.tags = tags;
        this.tagRelations = tagRelations;
        this.transactions = transactions.reverse();
    }

    getAccountTypes(data) {
        return data;
    }

    getAccounts(accountTypes, data) {
        let accounts = [];
        let findedAccountType;

        data.forEach(account => {
            account.account_type = null;
            findedAccountType = accountTypes.find(at => at.id === account.account_type_id);
            if (findedAccountType !== undefined) {
                account.account_type = findedAccountType;
            }

            accounts.push(account);
        });

        return accounts;
    }

    getConterparties(data, dataAddresses) {
        let conterparties = [];
        
        data.forEach(conterparty => {
            conterparty.addresses = dataAddresses.filter(a => a.conterparty_id === conterparty.id);
        
            conterparties.push(conterparty);
        })

        return conterparties;
    }

    getItems(data) {
        return data;
    }

    getPositions(items, data) {
        let positions = [];
        let finded;

        data.forEach(position => {
            position.item = null;
            if (position.item_id !== null) {
                finded = items.find(i => i.id === position.item_id);
                if (finded !== undefined) {
                    position.item = finded;
                }
            }

            positions.push(position);
        })

        return positions;
    }

    findRoot(data, id) {
        let f = data.find(d => d.id === id);
        if (f !== undefined) {
            if (f.parent_id === null) {
                return f;
            }
            else {
                return this.findRoot(data, f.parent_id);
            }
        }
    }

    getTags(data) {
        data.forEach(d => {
            //d.root = this.findRoot(data, d.id);
        })

        return data;
    }

    getTagRelations(tags, data) {
        let tr = [];
        let finded;

        data.forEach(trr => {
            trr.tag = null;
            if (trr.tag_id !== null) {
                finded = tags.find(t => t.id === trr.tag_id);
                if (finded !== undefined) {
                    trr.tag = finded;
                }
            }

            tr.push(trr);
        })
        

        return tr;
    }

    getTransactions(accounts, conterparties, positions, tags, tagRelations, data) {
        let transactions = [];
        let finded;

        data.forEach(transaction => {
            // account
            transaction.account = null;
            if (transaction.account_id !== null) {
                finded = accounts.find(a => a.id === transaction.account_id);
                if (finded !== undefined) {
                    transaction.account = finded;
                }
            }    

            // account destination
            transaction.accountDestination = null;
            if (transaction.account_destination_id !== null) {
                finded = accounts.find(a => a.id === transaction.account_destination_id);
                if (finded !== undefined) {
                    transaction.accountDestination = finded;
                }
            }  

            // conterparty
            transaction.conterparty = null;
            if (transaction.conterparty_id !== null) {
                finded = conterparties.find(c => c.id === transaction.conterparty_id);
                if (finded !== undefined) {
                    transaction.conterparty = finded;
                }
            }   

            // positions
            transaction.positions = [];
            let pp = [];
            positions.filter(p => p.transaction_id === transaction.id).forEach(p => {
                p.relations = tagRelations.filter(tr => tr.type === 'position' && tr.object_id === p.id);
                p.tags = [];
                p.relations.forEach(r => {
                    r.tag.relation_id = r.id;
                    p.tags.push(r.tag);
                });
                p.type = transaction.transaction_type;
                 
                pp.push(p);
            });

            transaction.positions = pp;

            //transaction.total_amount = transaction.total_amount.toFixed(2);

            transactions.push(transaction);
        })

        return transactions;
    }


}

const dataStore = new Data();
export { dataStore }