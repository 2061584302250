// Libraries, components, etc. //
import React from 'react';
import styled from 'styled-components';
import * as vars from '../constants';

//#region Styles
const TextareaContainer = styled.div`
    position: relative; 
    margin-top: 0.7rem;
    min-height: 2.5rem;
`;
const TextareaLabel = styled.label`
    position: absolute;
    top: ${props => props.isFocused || props.value !== '' ? '0' : '10%'};
    left: 0;
    display: flex;
    transform: ${props => props.isFocused || props.value !== '' ? 'translateY(-50%)' : 'translateY(-10%)'};
    transform-origin: left top;
    transition: 0.1s ease-out;
    padding: 0 0.3rem;
    background: transparent;
    pointer-events: none;
    font-size: 0.9rem;
    color: ${props => getColor(props.isFocused, props.value !== '', true)};
`;
const TextareaObject = styled.textarea`
    transition: 0.1s ease-out;
    border: 1px solid transparent;
    border-radius: 0.5rem;
    width: 100%;
    height: auto;
    padding: 1rem 0.7rem;
    background-color: transparent;
    color: ${props => getColor(props.isFocused, props.value !== '', true)};//${vars.COLOR_TEXT_MAIN};
    font-size: 1rem;
    outline: none;
`;
const Border = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    border-left: 1px solid ${props => getColor(props.isFocused, props.value !== '')};
    border-bottom: 1px solid ${props => getColor(props.isFocused, props.value !== '')};
    border-radius: .25rem;
    width: 100%;
    height: 100%;
    pointer-events: none;
`;
const TextareaLabelText = styled.span`
    width: 100%;
    padding: 0 0.3rem;
    font-size: ${props => props.isFocused || props.value !== '' ? '0.9rem' : '1rem'};
    color: transparent;
`;
const BorderStart = styled.div`
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    border-top: 1px solid ${props => getColor(props.isFocused, props.value !== '')};
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    width: 0.5rem;
    height: 100%;
`;
const BorderLabel = styled.div`
    position: relative;
    flex: 1;
    display: flex;
    transition: border-top 0.1s ease;
    margin-left: 0.5rem;
    border-top: ${props => props.isFocused || props.value !== '' ? '0' : '1px'} solid ${vars.COLOR_BORDER};
    height: 100%;
`;
const BorderEnd = styled.div`
    content: " ";
    position: relative;
    top: 0;
    border-top: 1px solid ${props => getColor(props.isFocused, props.value !== '')};
    border-right: 1px solid ${props => getColor(props.isFocused, props.value !== '')};
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    height: 100%;
    width: 100%;
`;
//#endregion

class Textarea extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isFocused: false
        }

        this._onFocus = this._onFocus.bind(this);
        this._onBlur = this._onBlur.bind(this);
    }

    render() {
        const { isFocused } = this.state;
        const {
            id,
            type,
            value,
            onChange,
            label
        } = this.props;

        return(
            <TextareaContainer>
                <TextareaObject id={id} rows='3' type={type} onChange={onChange} value={value} isFocused={isFocused} onFocus={() => this._onFocus()} onBlur={() => this._onBlur()}/>
                <Border isFocused={isFocused} value={value}>
                    <BorderStart isFocused={isFocused} value={value}/>
                    <BorderLabel isFocused={isFocused} value={value}>
                    <TextareaLabel isFocused={isFocused} value={value}>{label}</TextareaLabel>
                        <TextareaLabelText isFocused={isFocused} value={value}>{label}</TextareaLabelText>
                    </BorderLabel>
                    <BorderEnd isFocused={isFocused} value={value}/>
                </Border>
            </TextareaContainer>
        )
    }

    _onFocus(event) {
        this.setState({isFocused: true})
    }

    _onBlur() {
        this.setState({isFocused: false})
    }
}

function getColor(_isFocused = false, _hasValue = false, _isText = false) {
    let color = '';

    if (_isFocused) {
        color = _hasValue ? vars.COLOR_INFO : vars.COLOR_DANGER;
    }
    else {
        color = _hasValue ? vars.COLOR_SECONDARY : _isText ? vars.COLOR_TEXT_SECONDARY : vars.COLOR_BORDER;
    }

    return color;
}

export default Textarea;