// Libraries, components, etc. //
import { inject, observer } from "mobx-react";
import React from 'react';
import styled from 'styled-components';
import Header from '../components/header';
import * as vars from '../constants';
import { mainStore } from "../store/main";

//#region Styles
// Animations
const Page = styled.div`
	flex-direction: column;
    gap: .5rem;
	flex: 1;
	display: flex;
	border: 1px solid ${vars.COLOR_BORDER};
	border-radius: 1rem;
	padding: 1rem;
	overflow: hidden;
    width: 100%;
	background-color: ${vars.COLOR_WHITE};
`;
//#endregion

function Home(props) {
	return (
		<Page visible={mainStore.sidebarVisible}>
			<span>Время добавлять покупки!</span>
		</Page>
	);
}

export default observer(Home);
