import ModalAccount from './account';
import ModalConterparty from './conterparty';
import ModalItem from './item';
import ModalProfile from './profile';
import ModalTag from './tag';
import ModalTransaction from './transaction';
import { Modals as ModalNames } from '../../constants';

export default function Modals(props) {
    switch (props.name) {
        case 'profile':
            return <ModalProfile data={props.data}/>;
        case 'account':
            return <ModalAccount data={props.data}/>;
        case 'tag':
            return <ModalTag data={props.data}/>;
        case 'conterparty':
            return <ModalConterparty data={props.data}/>;
        case 'item':
            return <ModalItem data={props.data}/>;
        case ModalNames.transaction.name:
            return <ModalTransaction data={props.data}/>;
        default:
            return null;
    }
}