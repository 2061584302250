// Libraries, components, etc. //
import { toJS } from 'mobx';
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Branch from '../components/branch';
import Header from '../components/header';
import * as vars from '../constants';
import { Modals } from '../constants';
import { dataStore } from '../store/data';
import { mainStore } from '../store/main';
//#region Styles
const Page = styled.div`
	flex-direction: column;
    gap: .5rem;
	flex: 1;
	display: flex;
	border: 1px solid ${vars.COLOR_BORDER};
	border-radius: 1rem;
	padding: 1rem;
	overflow: hidden;
    width: 100%;
	background-color: ${vars.COLOR_WHITE};
`;
const TagList = styled.div`
	overflow-y: auto;
`;
const EmptyText = styled.span`
	display: ${props => props.hide ? 'none' : 'block'};
	color: ${vars.COLOR_TEXT_SECONDARY};
`;
//#endregion

function Tag(props) {
	const [tagsVisible, setTagsVisible] = useState([]);
	const tags = dataStore.tags;
	const positions = dataStore.positions;
	//const tree = buildTree(tags);
	const [tree, setTree] = useState([]);

	useEffect(() => {
		let p = buildTree(tags);
		setTree([...p]);

		let pp = [...p];
		let sum = 0;
		pp.forEach(ppp => {
			ppp.sum = calc(ppp);
			sum += ppp.sum;
		})
		console.log(JSON.parse(JSON.stringify(pp)));
		console.log('total:', sum);
		//console.log(JSON.parse(JSON.stringify(p)));
	}, [tags])

	function calc(node) {
		let sum = 0;

		if (node.children) {
			node.children.forEach(c => {
				sum += calc(c);
			})
		}

		sum += node.expense;
		return sum;
	}

	function buildTree(items, parent) {
		parent = parent || null;
		let result = [];
	
		items.forEach((item) => {
			if (item.parent_id === parent) {
				result.push(item);
				item.income = positions.filter(p => p.type === 'income' && p?.tags[0]?.id === item.id).reduce((sum, i) => sum += i.total_amount, 0);
				item.expense = positions.filter(p => p.type === 'expense' && p?.tags[0]?.id === item.id).reduce((sum, i) => sum += i.total_amount, 0);
				item.children = buildTree(items, item.id);
	


				if (!item.children.length) {
					delete item.children;
				}
			}
		});
		
		return result;
	}

	return (
		<Page visible={mainStore.sidebarVisible}>
			<EmptyText hide={tags.length !== 0}>Тут пока пусто. Стоит исправить!</EmptyText>
			<TagList>
			{
				tree.map(_tree => 
					<Branch key={_tree.id} item={_tree} level={0}/>
				)
			}
			</TagList>
		</Page>
	);
}

export default observer(Tag);
