export const getToken = function() {
    return localStorage.getItem('token') || '';
}

export function setToken(_token) {
    localStorage.setItem('token', _token);
}

export function removeToken() {
    localStorage.removeItem('token');
}

export function getCurrencySymbol(_currency = '') {
    if (_currency === '' || _currency === null || _currency === undefined)
        return '';

    switch (_currency) {
        case 'rub':
            return '₽';
        case 'usd':
            return '$';
        case 'eur':
            return '€';
        default:
            return '';
    }
}