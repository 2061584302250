// Libraries, components, etc. //
import axios from 'axios';
import { action, makeObservable, observable } from "mobx";
import { toast } from 'react-toastify';
import { config } from '../env';
import { getToken } from '../utils';

const NODE_ENV = process.env.NODE_ENV || 'production';

class Position {
    token = '';
    positions = [];

    constructor() {
        makeObservable(this, {
            token: observable,
            positions: observable,
            create: action,
            update: action,
            delete: action
        });

        this.token = getToken();
        axios.defaults.headers.common['x-user-language'] = 'ru';
        axios.defaults.headers.common['x-app-name'] = 'argent';
        axios.defaults.headers.common['x-access-token'] = this.token;
        this.loadData();

        this.create = this.create.bind(this);
        this.update = this.update.bind(this);
        this.delete = this.delete.bind(this);
    }

    loadData() {
        axios.get(config.api.host_andtask[NODE_ENV]+config.api.position.getall)
        .then(result => {
            if (result.data.status) {
                this.positions = result.data.data;
            }
            else {
                toast.error(result.data.text);
            }
        })
    }

    create(_data) {
        const positionData = {
            name: _data.position.name,
            transaction_id: _data.position.transaction_id,
            item_id: _data.position.item_id,
            description: _data.position.description,
            amount: _data.position.amount,
            qty: _data.position.qty,
            total_amount: _data.position.total_amount
        };

        if (positionData.item_id === 0) {
            const d = {
                name: positionData.name,
                description: positionData.description
            }
            axios.post(config.api.host_andtask[NODE_ENV] + config.api.item.create, d)
            .then(result => {
                if (result.data.status) {
                    positionData.item_id = result.data.data.id;
                    axios.post(config.api.host_andtask[NODE_ENV] + config.api.position.create, positionData)
                    .then(result => {
                        if (result.data.status) {
                            const positionId = result.data.data.id;
                            _data.tags.map(tag => {
                                const tagData = {
                                    object_id: positionId,
                                    type: 'position',
                                    tag_id: tag.tag.id
                                }
                                
                                _data.store.TagRelation.create(tagData);
                            })
                            
                            this.loadData();
                        }
                        else {
                            toast.error(result.data.text);
                        }
                    })
                    .catch(error => {
                        toast.error(error.response.data.text);
                    })
                }
                else {
                    toast.error(result.data.text);
                }
            })
            .catch(error => {
                toast.error(error.response.data.text);
            })
        }
        else {
            axios.post(config.api.host_andtask[NODE_ENV] + config.api.position.create, positionData)
            .then(result => {
                if (result.data.status) {
                    const positionId = result.data.data.id;
                    _data.tags.map(tag => {
                        const tagData = {
                            object_id: positionId,
                            type: 'position',
                            tag_id: tag.tag.id
                        }
                        
                        _data.store.TagRelation.create(tagData);
                    })
                    
                    this.loadData();
                }
                else {
                    toast.error(result.data.text);
                }
            })
            .catch(error => {
                toast.error(error.response.data.text);
            })
        }
    }

    update(_data) {
        const data = {
            id: _data.id,
            name: _data.name,
            description: _data.description,
            amount: _data.amount,
            qty: _data.qty,
            total_amount: _data.total_amount
        };

        axios.put(config.api.host_andtask[NODE_ENV] + config.api.position.update, data)
        .then(result => {
            if (result.data.status) {
                this.loadData();
            }
            else {
                toast.error(result.data.text);
            }
        })
        .catch(error => {
            toast.error(error.response.data.text);
        })
    }

    delete(_id) {
        axios.delete(config.api.host_andtask[NODE_ENV] + config.api.position.delete + _id)
        .then(result => {
            if (result.data.status) {
                this.loadData();
            }
            else
                toast.error(result.data.text);
        })
        .catch(error => {
            toast.error(error.response.data.text);
        })
    }
}

export default new Position();