// Libraries, components, etc. //
import { inject, observer } from "mobx-react";
import React from 'react';
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled, {keyframes} from 'styled-components';
import Auth from './pages/auth';
import Sidebar from "./components/sidebar";
import Account from './pages/account';
import Conterparty from './pages/conterparty';
import Home from './pages/home';
import Item from './pages/item';
import Modal from './components/modal';
import Tag from './pages/tag';
import Transaction from './pages/transactions';
import * as vars from './constants';
import { setDefaultLocale } from  "react-datepicker";
import { Device } from "./constants";
import ru from 'date-fns/locale/ru';
import Header from "./components/header_new";
setDefaultLocale('ru');

//#region Styles
const gradient = keyframes`
  	0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
`;
const Page = styled.div`
	position: relative;
	z-index: 120;
	flex-direction: row;
	display: flex;
	//animation: ${gradient} 15s ease infinite;
	width: 100vw;
	height: 100vh;
	padding: 1rem;
	background: linear-gradient(-45deg, ${vars.COLOR_PRIMARY}, ${vars.COLOR_SECONDARY});
	background-position: 100% 50%;
	background-size: 400% 400%;
`;
const Columns = styled.div`
	flex-direction: column;
	display: flex;
	flex: 1;
	transition: /*width ease-in .3s, visibility ease-in .1s,*/ transform ease-in .3s;
	@media ${Device.mobileM} {
		transform: ${props => props.sidebarVisible ? 'translateX(100vw)' : 'translateX(0)'};
	}

	@media ${Device.tablet} {
		transform: none;
		width: auto;
	}
`;
//#endregion

function App(props) {
	const token = props.store.User.token;
	const modalVisible = props.store.Main.modalVisible;
	const modalName = props.store.Main.modalName;
	const modalData = props.store.Main.modalData;
	const modalType = props.store.Main.modalType;
	const sidebarVisible = props.store.Main.sidebarVisible;

	if (token === '')
			return (
				<div>
					<ToastContainer
						position="top-right"
						autoClose={4000}
						hideProgressBar={true}
						newestOnTop
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
						limit={10}/>
					<Auth/>
				</div>
			);

		return (
			<Page>
				<ToastContainer
					position="top-right"
					autoClose={4000}
					hideProgressBar={true}
					newestOnTop
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					limit={10}/>
				<Modal visible={modalVisible} name={modalName} data={modalData} type={modalType} onClickClose={props.store.Main.hideModal}/>
				<Sidebar />
				<Columns sidebarVisible={sidebarVisible}>
					<Header />
					<Routes>
						<Route exact path="/" element={<Home/>}/>
						<Route path='/accounts' element={<Account/>}/>
						<Route path='/conterparties' element={<Conterparty/>}/>
						<Route path='/tags' element={<Tag/>}/>
						<Route path='/transactions' element={<Transaction/>}/>
						<Route path='/items' element={<Item/>}/>
					</Routes>
				</Columns>
			</Page>
		);
}

export default inject("store")(observer(App));
