import styled from "styled-components";
import * as vars from '../../constants';

const Component = styled.div`
    margin: 0 auto;
    border-radius: .25rem;
	padding: .25rem .5rem;
	background-color: ${props => props.background};
	font-size: .85rem;
	text-align: center;
    width: 75px;
	color: white;
`;

const typeColors = {
    'income': vars.COLOR_SUCCESS,
    'expense': vars.COLOR_DANGER,
    'transfer': vars.COLOR_INFO
}

const typeNames = {
    'income': 'Доход',
    'expense': 'Расход',
    'transfer': 'Перевод'
}

const TransactionType = ({type}) => 
    <Component background={typeColors[type]}>
        {typeNames[type]}
    </Component>;

export default TransactionType;