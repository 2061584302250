// Libraries, components, etc. //
import {toJS} from 'mobx';
import axios  from 'axios';
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Button from '../button';
import Icon from '../../icons';
import Input from '../input';
import Textarea from '../textarea';
import TextareaNew from '../textarea_new';
import Select from '../select';
import * as vars from '../../constants';
import { Device } from '../../constants';
import { dataStore } from '../../store/data';
import { mainStore } from '../../store/main';
import positionAdapter from '../../adapters/position';
import itemAdapter from '../../adapters/item';
import tagRelationAdapter from '../../adapters/tagRelation';
import transactionAdapter from '../../adapters/transaction';
import tagAdapter from '../../adapters/tag';
import { v4 as uuidv4 } from 'uuid';
//import Input from '../input_new';

//#region Styles
const ModalComponent = styled.div`
    flex-direction: column;
    gap: 1rem;
    display: flex;
    overflow: auto;
    min-width: ${props => props.wide ? '70vw' : '20vw'};
    max-height: 90vh;
    height: 100%;
    padding: 1rem;

    @media ${Device.mobileM} {
        width: 90vw;
    }

    @media ${Device.tablet} {
        width: auto;
        min-width: ${props => props.wide ? '70vw' : '20vw'};
    }
`;
const ContentSection = styled.div`
    flex-direction: row;
    gap: 1rem;
    display: flex;

    @media ${Device.mobileM} {
        flex-direction: column;
    }

    @media ${Device.tablet} {
        flex-direction: row;
    }
`;
const Content = styled.div`
    flex-direction: column;
    gap: .5rem;
    display: flex;
    flex: ${props => props.flex ?? 1};
`;
const Row = styled.div`
    flex-direction: row;
    gap: .5rem;
    display: flex;
`;
const FormRow = styled.div`
    flex-direction: ${props => props.column ? 'column' : 'row'};
    display: ${props => props.visible ? 'flex' : 'none'};
`;
const Form = styled.div`
    flex: ${props => props.fill ? props.g ? '2 1' :'1 1' : '0'};
    flex-direction: column;
    gap: 0.5rem;
    display: ${props => props.hidden ? 'none' : 'flex'};
    padding: .5rem 1rem;
`;
const ButtonRow = styled.div`
    flex-direction: row;
    justify-content: center;
    gap: 0.5rem;
    display: flex;
`;
const StatusButton = styled.div`
    flex-direction: row;
    display: flex;
    overflow: hidden;
    border-radius: .25rem;
`;
const StatusButtonItem = styled.span`
    flex: 1;
    transition: ease-in .3s background-color; 
    padding: .25rem;
    background-color: ${props => props.isActive ? props.color : vars.COLOR_GRAY};
    cursor: pointer;
    font-size: smaller;
    text-align: center;
    color: ${vars.COLOR_WHITE};
    user-select: none;
`;
const SuggestionList = styled.div`
    flex-direction: column;
    gap: .25rem;
    display: flex;
`;
const SectionButton = styled.div`
    flex-direction: row;
    gap: .5rem;
    display: flex;
`;
const PositionTagList = styled.div`
    flex-direction: row;
    gap: .5rem;
    flex-wrap: wrap;
    display: flex;
`;
const PositionTagItem = styled.span`
    border-radius: .25rem;
    padding: .25rem .5rem;
    background-color: ${vars.COLOR_PRIMARY};
    cursor: ${props => props.changeCursor ? 'not-allowed' : 'default'};
    font-size: 0.9rem;
    color: ${vars.COLOR_WHITE};
`;
const FormTable = styled.div`
    flex: 2 1;
    overflow-y: auto;
    max-height: 60vh;
    padding: 0 1rem;
`;
const Table = styled.table`
	border-collapse: collapse;
	width: 100%;
    table-layout: fixed;
`;
const TableHead = styled.th`
	position: sticky;
    top: 0;
    border-bottom: 1px solid ${vars.COLOR_BORDER};
	padding: .5rem;
    background-color: ${vars.COLOR_WHITE};
    font-size: 0.9rem;
`;
const TableData = styled.td`
	border-bottom: ${props => props.border ? '1px solid '+vars.COLOR_BORDER : 'none'};
	padding: ${props => props.isTag ? '0 0.5rem  0.5rem 0.5rem' : '.5rem'};
	font-size: 0.9rem;
	color: ${props => props.amount ? props.color : vars.COLOR_TEXT_MAIN};
`;
const TableRow = styled.tr`
    border-bottom: ${props => props.border ? '1px solid '+vars.COLOR_BORDER : 'none'};
    
    &:hover {
		cursor: pointer;
	}
`;
const SuggestionItem = styled.span`
    border: 1px solid ${vars.COLOR_PRIMARY};
    border-radius: .25rem;
    padding: .25rem;
    color: ${vars.COLOR_PRIMARY};
    cursor: pointer;
`;
const PositionTitle = styled.span`
    justify-content: center;
    display: ${props => props.visible ? 'flex' : 'none'};
    width: 100%;
    padding: .5rem;
    text-align: center;
    font-weight: 300;
`;
//#endregion

const _states = {
    ADD: 0,
    UPDATE: 1,
    DELETE: 2    
}

const tagList = [];
function ModalTransaction(props) {

    const accountTypes = dataStore.accounts;
    const conterparties = dataStore.conterparties;
    const tags = dataStore.tags;
    const items = dataStore.items;

    const [id, setId] = useState(0);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [conterpartyId, setConterpartyId] = useState(0);
    const [transactionType, setTransactionType] = useState('income');
    const [transactionDate, setTransactionDate] = useState(null);
    const [transactionTime, setTransactionTime] = useState(null);
    const [amount, setAmount] = useState(0);
    const [accountTypeId, setAccountTypeId] = useState(0);
    const [accountTransferTypeId, setAccountTransferTypeId] = useState(0);
    const [statePositions, setStatePositions] = useState([]);
    const [positionId, setPositionId] = useState(0);
    const [positionItemId, setPositionItemId] = useState(0);
    const [positionName, setPositionName] = useState('');
    const [positionDescription, setPositionDescription] = useState('');
    const [positionQty, setPositionQty] = useState(0);
    const [positionTotalAmount, setPositionTotalAmount] = useState(0);
    const [positionAmount, setPositionAmount] = useState(0);
    const [tagId, setTagId] = useState(0);
    const [tagTree, setTagTree] = useState([]);
    const [positionTags, setPositionTags] = useState([]);
    const [positionItemTags, setPositionItemTags] = useState([]);
    const [isPositionEdit, setPositionEdit] = useState(false);
    const [temporaryTags, setTemporaryTags] = useState([]);
    const [sourceTagRelations, setSourceTagRelations] = useState([]);

    // new
    const [tempTags, setTempTags] = useState([]);
    const [positionState, setPositionState] = useState(-1);


    useEffect(() => {
        if (props.data !== null) {
            const date = new Date(props.data.transaction_datetime);
            setId(props.data.id);
            setName(props.data.name);
            setDescription(props.data.description);
            setConterpartyId(props.data.conterparty_id);
            setAmount(props.data.total_amount);
            setTransactionType(props.data.transaction_type);
            setTransactionDate(''+date.getFullYear()+'-'+(date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : (date.getMonth()+1))+'-'+(date.getDate()< 10 ? '0'+date.getDate() : date.getDate()));
            setTransactionTime(''+(date.getHours() < 10 ? '0'+date.getHours() : date.getHours())+':'+(date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()));
            setAccountTypeId(props.data.account_id);
            setAccountTransferTypeId(props.data.account_destination_id);
            setPositions([...props.data.positions]);
        }
        
    }, [props.data])

	
    const [positions, setPositions] = useState([]);
    

    const tagRelations = dataStore.tagRelations;
    let totalAmount = 0;
    if (id === 0) {
        positions.map(p => {
            totalAmount += parseFloat(p.total_amount);
        })
    }
    else {
        positions.filter(p => p.transaction_id === id).map(p => {
            totalAmount += parseFloat(p.total_amount);
        })
    }
    
    tagList.splice(0);
    buildTree(toJS(tags))

    async function setItem(_item) {
        const { status, data, message} = await tagAdapter.getSuggestion(_item.id);
        if (!status) {
            toast.error(message);
        }

        if (data !== 0) {
            addTag(data);
        }

        setPositionItemId(_item.id);
        setPositionName(_item.name);
    }

    /**
     * Добавление тега
     * Если id не указан, то берет tagId
     * @param id id тега
     * */
    const addTag = (id) => {
        if (id === undefined) {
            id = tagId;
        }

        if (id === 0) {
            toast.error('Не выбран тег');
            return;
        }

        const tag = tagList.find(t => t.id === parseInt(id));
        if (tag === undefined) {
            toast.error('Не найден тег');
            return;
        }

        const tempArray = [...tempTags];
        tag._state = _states.ADD;
        tempArray.push(tag);
        setTempTags([...tempArray]);
        setTagId(0);
    }

    /** 
     * Удаление тега
     * @param index Индекс удаляемого тега
     * */
    function deleteTag(index) {
        const tempArray = [...tempTags];
        
        const deletedTag = tempArray[index];
        if (deletedTag._state !== undefined && deletedTag._state === _states.ADD) {
            tempArray.splice(index, 1);
        }
        else {
            deletedTag._state = _states.DELETE;
            tempArray[index] = deletedTag;
        }

        setTempTags([...tempArray]);
    }

    function addPosition() {
        const total = (parseFloat(positionAmount) * parseFloat(positionQty)).toFixed(2);
        const data = {
            id: uuidv4(),
            item_id: positionItemId,
            name: positionName,
            description: positionDescription,
            qty: positionQty,
            amount: positionAmount,
            total_amount: total,
            _state: _states.ADD
        };

        const _tags = [];
        tempTags.forEach(tag => {
            tag._state = _states.ADD;
            _tags.push(tag);
        });
        data.tags = _tags;
        const p = [...positions];
        p.push(data);
        setPositions(p);

        // clear
        clearPosition();
        let total1 = 0;
        p.forEach(p => {
            total1 += p.total_amount;
        })
        setAmount(total1);
        totalAmount = total1;
    }

    function updatePosition() {
        const data = {
            id: positionId,
            item_id: positionItemId,
            name: positionName,
            description: positionDescription,
            qty: positionQty,
            amount: positionAmount,
            total_amount: positionAmount * positionQty,
            _state: positionState === _states.ADD ? _states.ADD : _states.UPDATE,
            tags: tempTags
        };

        const idx = positions.findIndex(t => t.id === data.id);
        if (idx === -1) {
            toast.error('Не найдена позиция');
            return;
        }

        const p = [...positions];
        p[idx] = {...p[idx], ...data};
        setPositions(p);

        clearPosition();
        let total1 = 0;
        p.forEach(p => {
            total1 += p.total_amount;
        })
        setAmount(total1);
        totalAmount = total1;
    }

    function deletePosition(position) {
        const idx = positions.findIndex(p => p.id === position.id);
        if (idx === -1) {
            toast.error('Не удалось найти позицию');
            return;
        }

        const p = [...positions];
        if (position._state !== undefined && position._state === _states.ADD) {
            p.splice(idx, 1);
        }
        else {
            position._state = _states.DELETE;
            p[idx] = position;
        }
        setPositions(p);
    }

    function clearPosition() {
        setPositionId(0);
        setPositionItemId(0);
        setPositionName('');
        setPositionQty('');
        setPositionDescription('');
        setPositionAmount('');
        setPositionEdit(false);
        setTempTags([]);
        setPositionState(-1);
    }

    function initPosition(position) {
        setTempTags(JSON.parse(JSON.stringify(position.tags)));
        setPositionEdit(true);
        setPositionId(position.id);
        setPositionItemId(position.item_id);
        setPositionName(position.name);
        setPositionDescription(position.description);
        setPositionAmount(position.amount);
        setPositionQty(position.qty);
        setPositionTotalAmount(position.total_amount);
        if (position._state !== undefined) {
            setPositionState(position._state);
        }
        else {
            setPositionState(-1);
        }
        
    }

    async function createTransaction(transaction) {
        let total1 = 0;
        if (transactionType === 'transfer') {
            total1 = amount;
            transaction.total_amount = total1
        }

        const { status, data, message} = await transactionAdapter.create(transaction);
        if (!status) {
            toast.error(message);
            return;
        }

        for (let i = 0; i < positions.length; i++) {
            const d = positions[i];
            d.transaction_id = data.id;
            const s = await createPosition(d);
            if (!s) {
                return;
            }
        }

        await dataStore.fetch();
        mainStore.hideModal();
    }

    async function createRelation(data1) {
        const res = await tagRelationAdapter.create(data1);
        
        if (!res.status) {
            toast.error(res.message);
            return false;
        }

        return true;
    }

    async function createPosition(data) {
        if (data.item_id === 0) {
            const res = await itemAdapter.create({name: positionName, description: positionDescription});
            if (!res.result) {
                toast.error(res.message);
                return false;
            }

            data.item_id = res.data.id;
        }

        const res1 = await positionAdapter.create(data);
        if (!res1.status) {
            toast.error(res1.message);
            return false;
        }

        for (let i = 0; i < data.tags.length; i++) {
            const tagResponse = {
                object_id: res1.data.id,
                type: 'position',
                tag_id: data.tags[i].id
            };
            
            if (!createRelation(tagResponse)) {
                return false;
            }
        }

        return true;
    }

    async function updateTransaction(transaction) {
        const { status, data, message} = await transactionAdapter.update(transaction);
        if (!status) {
            toast.error(message);
            return;
        }

        // positions
        for (let i = 0; i < positions.length; i++) {
            const d = positions[i];
            let s;
            if (d._state === _states.ADD) {
                s = await createPosition(d);
            }
            else if (d._state === _states.UPDATE) {
                s = await positionAdapter.update(d);
                s = s.status;
            }
            else if (d._state === _states.DELETE) {
                s = await positionAdapter.remove(d.id);
                s = s.status;
            }
            else {
                s = true;
            }

            if (!s) {
                return;
            }

            for (let j = 0; j < d.tags.length; j++) {
                let s;
                if (d.tags[j]._state === _states.ADD) {
                    const tagResponse = {
                        object_id: d.id,
                        type: 'position',
                        tag_id: d.tags[j].id
                    };
                    s = await createRelation(tagResponse);
                }
                else if (d.tags[j]._state === _states.DELETE) {
                    s = await tagRelationAdapter.remove(d.tags[j].relation_id);
                    s = s.status;
                }
                else {
                    s = true;
                }
                if (!s) {
                    return;
                }
            }
        }

        // tags
    }

    async function deleteTransaction() {
        const { status, data, message} = await transactionAdapter.remove(id);
        if (!status) {
            toast.error(message);
            return;
        }
    }

    function checkTransaction() {
        if (name === '' || name === null || name === undefined) {
            toast.error('Название не заполнено');
            return false;
        }

        if (accountTypeId === '' || accountTypeId === null || accountTypeId  === undefined || accountTypeId === 0) {
            toast.error('Не выбран счет');
            return false;
        }

        if (transactionType === 'transfer' && (accountTransferTypeId === '' || accountTransferTypeId === null || accountTransferTypeId  === undefined || accountTransferTypeId === 0)) {
            toast.error('Не выбран счет для перевода');
            return false;
        }

        if (transactionType !== 'transfer' && (conterpartyId === '' || conterpartyId === null || conterpartyId  === undefined || conterpartyId === 0)) {
            toast.error('Не выбрана организация');
            return false;
        }

        if (transactionDate === '' || transactionDate === null || transactionDate === undefined) {
            toast.error('Не выбрана дата');
            return false;
        }

        if (transactionTime === '' || transactionTime === null || transactionTime === undefined) {
            toast.error('Не выбрано время');
            return false;
        }

        //if (amount === '' || amount === null || amount === undefined || amount === 0) {
        //    toast.error('Итоговая сумма не может быть равна 0');
        //    return false;
        //}

        if (transactionType !== 'transfer' && id === 0 && positions.length === 0) {
            toast.error('Отсутствуют позиции');
            return false;
        }

        return true;
    }

    function positionFunction(_type = '', _params) {
        if (_type === '')
            return;

        

        if (_type !== 'delete' && _type !== 'edit') {
            if (positionName === '' || positionName === undefined || positionName === null) {
                toast.error('Название позиции не заполнено');
                return;
            }

            if (positionAmount === '' || positionAmount === undefined || positionAmount === null || positionAmount === 0) {
                toast.error('Стоимость позиции не заполнена');
                return;
            }

            if (positionAmount < 0) {
                toast.error('Стоимость позиции должна быть положительна');
                return;
            }

            if (positionQty === '' || positionQty === undefined || positionQty === null || positionQty === 0) {
                toast.error('Количество позиции не заполнено');
                return;
            }

            if (positionQty <= 0) {
                toast.error('Количество позиции должно быть положительным');
                return;
            }
        }

        const total = (parseFloat(positionAmount) * parseFloat(positionQty)).toFixed(2);
        switch (_type) {
            case 'add':
                addPosition();
                break;
            case 'edit':
                initPosition(_params.position);
                break;
            case 'cancel':
                clearPosition();
                break;
            case 'delete':
                deletePosition(_params);
                break;
            case 'update':
                updatePosition();
                break;
            default:
                break;
        }
    } 

    function getAmountColor() {
        switch (transactionType) {
            case 'income':
                return vars.COLOR_SUCCESS;
            case 'expense':
                return vars.COLOR_DANGER;
            default:
                return vars.COLOR_TEXT_MAIN;
        }
    }

    function setActiveState(_type = '') {
        switch (_type) {
            case 'expense':
                setTransactionType(_type);
                setAccountTransferTypeId(0);
                setAmount(0);
                break;
            case 'transfer':
                setTransactionType(_type);
                break;
            default:
                setTransactionType(_type);
                setAccountTransferTypeId(0);
                setAmount(0);
                break;
        }
    }

    async function transactionFunction(type = 'none', _data) {
        if (type === 'none')
            return;

        if (type === 'delete') {
            await deleteTransaction();
        }
        else {
            if (!checkTransaction()) {
                return;
            }
    
            const date = new Date(transactionDate);
            date.setHours(parseInt(transactionTime.substring(0,2)), parseInt(transactionTime.substring(3,5)));
            const transactionData = {
                id: id,
                name: name,
                description: description,
                conterparty_id: conterpartyId,
                conterparty_address_id: null,
                account_id: accountTypeId,
                account_destination_id: transactionType === 'transfer' ? accountTransferTypeId : null,
                total_amount: type === 'delete' ? 0 :_data.total_amount,
                transaction_datetime: date.toISOString(),
                transaction_type: transactionType
            };
    
            switch (type) {
                case 'add':
                    await createTransaction(transactionData);
                    break;
                case 'update':
                    await updateTransaction(transactionData);
                    break;
                case 'delete':
                    await deleteTransaction();
                    break; 
                default:
                    break;
            }
        }

        await dataStore.fetch();
        mainStore.hideModal();
    }

    function buildTree(items, parent, parent_name) {
		parent = parent || null;
        parent_name = parent_name || '';
		let result = [];
	
		items.forEach((item) => {
			if (item.parent_id === parent) {
                tagList.push({
                    full_path: (parent_name === '' ? parent_name : parent_name + ' / ') + item.name, 
                    id: item.id,
                    name: item.name
                });
                result.push(item);
				item.children = buildTree(items, item.id, (parent_name === '' ? parent_name : parent_name + ' / ') + item.name);
	
				if (!item.children.length) {
					delete item.children;
				}
			}
		});
        
		return result;
	}

    return (
        <ModalComponent wide={transactionType !== 'transfer'}>
            <ContentSection>
                <Content>
                    <StatusButton>
                        <StatusButtonItem isActive={transactionType === 'income'} color={vars.COLOR_SUCCESS} onClick={() => setActiveState('income')}>Доход</StatusButtonItem>
                        <StatusButtonItem isActive={transactionType === 'expense'} color={vars.COLOR_DANGER} onClick={() => setActiveState('expense')}>Расход</StatusButtonItem>
                        <StatusButtonItem isActive={transactionType === 'transfer'} color={vars.COLOR_INFO} onClick={() => setActiveState('transfer')}>Перевод</StatusButtonItem>
                    </StatusButton>
                <Input type='text' id='name' label='Название' value={name} onChange={(event) => setName(event.target.value)}/>
                <TextareaNew type='text' rows='3' id='description' label='Описание' value={description} onChange={(event) => setDescription(event.target.value)}/>
                
                    <Select id='accountTypeId' value={accountTypeId}  onChange={(event) => setAccountTypeId(event.target.value)}>
                        <option hidden>{transactionType === 'transfer' ? 'Из счета' : 'Выберите счет'}</option>
                    {
                        accountTypes.map((accountType, index) => 
                            <option key={index} value={accountType.id}>{accountType.name}</option>
                        )
                    }
                    </Select>
                    {
                        transactionType === 'transfer' ?
                        <Select id='accountTransferTypeId' value={accountTransferTypeId}  onChange={(event) => setAccountTransferTypeId(event.target.value)}>
                            <option hidden>На счет</option>
                        {
                            accountTypes.filter(accountType => accountType.id !== parseInt(accountTypeId)).map((accountType, index) => 
                                <option key={index} value={accountType.id}>{accountType.name}</option>
                            )
                        }
                        </Select> : null        
                    }
                    <Select id='conterpartyId' value={conterpartyId} onChange={(event) => setConterpartyId(event.target.value)}>
                        <option hidden>Выберите организацию</option>
                    {
                        conterparties.map((conterparty, index) => 
                            <option key={index} value={conterparty.id}>{conterparty.name}</option>
                        )
                    }
                    </Select>
                    <Row>
                    <Input type='date' id='transactionDate' label='Дата' value={transactionDate} onChange={(event) => setTransactionDate(event.target.value)}/>
                    <Input type='time' id='transactionTime' label='Время' value={transactionTime} onChange={(event) => setTransactionTime(event.target.value)}/>
                    </Row>
                    
                    <Input type='number' id='amount' readOnly={transactionType !== 'transfer'} label='Сумма' value={Math.abs(transactionType === 'transfer' ? amount : totalAmount).toFixed(2) * (transactionType === 'expense' ? -1 : 1)} onChange={(event) => setAmount(event.target.value)}/>
            </Content>
            {
                transactionType !== 'transfer' && <Content>
                <PositionTitle visible={true}>{positionId === 0 ? 'Добавить' : 'Изменить'} позицию</PositionTitle>
                    <Input type='text' id='positionName' label='Название' value={positionName} onChange={(event) => setPositionName(event.target.value)}/>
                    <SuggestionList>
                    {
                        items.filter(item => positionName.toLocaleLowerCase() !== '' && item.name.toLocaleLowerCase().includes(positionName.toLocaleLowerCase()) && positionName !== item.name).slice(0,3).map(item =>
                            <SuggestionItem onClick={() => setItem(item)}>{item.name}</SuggestionItem>    
                        )
                    }
                    </SuggestionList>
                    <TextareaNew type='text' rows='3' id='positionDescription' label='Примечание' value={positionDescription} onChange={(event) => setPositionDescription(event.target.value)}/>
                    <Input type='number' id='positionAmount' label='Стоимость' value={positionAmount} onChange={(event) => setPositionAmount(event.target.value)}/>
                    <Input type='number' id='positionQty' label='Количество' value={positionQty} onChange={(event) => setPositionQty(event.target.value)}/>
                    <SectionButton>
                        <Select id='tagId' value={tagId} disabled={false} onChange={(event) => setTagId(event.target.value)}>
                            <option hidden>Выберите тег</option>
                        {
                            tagList.map((t, index) => 
                                <option key={index} value={t.id}>{t.full_path}</option>
                            )
                        }
                        </Select>
                        {
                            tagId !== 0 ?
                            <Icon name='add' width='24px' color={vars.COLOR_TEXT_SECONDARY} hoverColor={tagId !== 0 ? vars.COLOR_SUCCESS : vars.COLOR_DANGER}
                                onClick={() => addTag()}/> : null
                        }
                    </SectionButton>
                    <PositionTagList>
                    {
                        //temporaryTags.filter(t => t.status !== -1).map((tag, index) => 
                        tempTags.filter(position => position?._state !== _states.DELETE).map((tag, index) =>
                            <PositionTagItem changeCursor={true} onClick={() => deleteTag(index)}>{tag.name}</PositionTagItem>      
                        )
                    }
                    </PositionTagList>
                    <ButtonRow>
                    {
                        positionId !== 0 ?
                            <Button type='grey' size='0.9rem' fill={false} onClick={() => positionFunction('cancel')}>Отмена</Button> : null
                    }
                        <Button size='0.9rem' onClick={() => positionFunction(isPositionEdit ? 'update' : 'add', {tags: id === 0 ? positionTags : tagRelations})}>{isPositionEdit ? 'Сохранить' : 'Добавить позицию'}</Button>
                    </ButtonRow>
                </Content>
            }
            {
                transactionType !== 'transfer' && <Content flex='2'>
                    <PositionTitle visible={transactionType !== 'transfer'}>ПОЗИЦИИ</PositionTitle>
                    <Table>
                        <TableRow>
                            <TableHead>Название</TableHead>
                            <TableHead>Примечание</TableHead>
                            <TableHead>Количество</TableHead>
                            <TableHead>Стоимость</TableHead>
                            <TableHead>Итого</TableHead>
                            <TableHead></TableHead>
                        </TableRow>
                        {
                            positions.filter(position => position?._state !== _states.DELETE).map((position, index) =>
                                <>
                                    <TableRow onClick={(event) => {event.stopPropagation(); positionFunction('edit', {position: position, tags: id === 0 ? positionTags : tagRelations})}}>
                                        <TableData>{position.name}</TableData>
                                        <TableData>{position.description}</TableData>
                                        <TableData>{position.qty}</TableData>
                                        <TableData amount={true} color={getAmountColor()}>{position.amount}</TableData>
                                        <TableData amount={true} color={getAmountColor()}>{position.amount*position.qty}</TableData>
                                        <TableData>
                                        <Icon name='delete' onClick={(event) => {event.stopPropagation(); positionFunction('delete', position)}} width='16px' color={vars.COLOR_TEXT_SECONDARY} hoverColor={vars.COLOR_DANGER}/></TableData>
                                    </TableRow>
                                    <TableRow  border={true}>
                                        <TableData isTag={true} colSpan="6">
                                            <PositionTagList>
                                                {
                                                    position.tags.filter(p => p?._state !== _states.DELETE).map(tag => 
                                                        <PositionTagItem>{tag.name}</PositionTagItem>    
                                                )
                                                }
                                            </PositionTagList>
                                        </TableData>
                                    </TableRow>
                                </>
                            )
                        }
                        </Table>
                </Content>
            }
            </ContentSection>
            <ButtonRow>
            {
                id !== 0 ?
                    <Button type='danger' size='0.9rem' onClick={() => transactionFunction('delete')}>Удалить</Button> : null
            }
                <Button type='success' size='0.9rem' onClick={() => transactionFunction(id === 0 ? 'add' : 'update', {total_amount: Math.abs(transactionType === 'transfer' ? amount : totalAmount)* (transactionType === 'expense' ? -1 : 1)})}>{id === 0 ? 'Добавить' : 'Сохранить'}</Button>
            </ButtonRow>
        </ModalComponent>
    );
}

export default observer(ModalTransaction); 
