// Libraries, components, etc. //
import axios from 'axios';
import { action, makeObservable, observable } from "mobx";
import { toast } from 'react-toastify';
import { config } from '../env';
import { getToken, setToken, removeToken } from '../utils';
import { dataStore } from './data';

const NODE_ENV = process.env.NODE_ENV || 'production';

class User {
    token = '';
    userData = {};
    isSignIn = true;

    constructor() {
        makeObservable(this, {
            token: observable,
            userData: observable,
            isSignIn: observable,
            signIn: action,
            signUp: action,
            setState: action,
            logout: action,
            uploadProfileImage: action,
            update: action
        });

        this.loadData();

        this.signIn = this.signIn.bind(this);
        this.signUp = this.signUp.bind(this);
        this.setState = this.setState.bind(this);
        this.logout = this.logout.bind(this);
        this.uploadProfileImage = this.uploadProfileImage.bind(this);
        this.update = this.update.bind(this);
    }

    loadData() {
        axios.defaults.headers.common['x-user-language'] = 'ru';
        axios.defaults.headers.common['x-app-name'] = 'argent';
        this.token = getToken();
        
        if (this.token !== '') {
            axios.defaults.headers.common['x-access-token'] = this.token;
            axios.get(config.api.host_main[NODE_ENV] + config.api.main.get)
            .then(result => {
                if (result.data.status) {
                    this.userData = result.data.data;
                    if (!(result.data.data.firstName === undefined || result.data.data.lastName === undefined)) {
                        this.userData.placeholder = result.data.data.firstName.substr(0,1)+result.data.data.lastName.substr(0,1);
                    }
                    else {
                        this.userData.placeholder = '';
                    }
                    

                    // AccountType.loadData();
                    // Account.loadData();
                    // Conterparty.loadData();
                    // ConterpartyAddress.loadData();
                    // Item.loadData();
                    // Position.loadData();
                    // Tag.loadData();
                    // TagRelation.loadData();
                    // Transaction.loadData();
                    dataStore.fetch();
                }
                else {
                    toast.error(result.data.text);
                    removeToken();
                }
            })
            .catch(error => {
                toast.error(error.response.data.text);
            })
        }
    }

    signIn(_data) {
        const data = {
            user_name: _data.user_name,
            password: _data.password
        };
        axios.post(config.api.host_main[NODE_ENV] + config.api.main.sign_in, data)
        .then(result => {
            if (result.data.status) {
                this.token = result.data.access_token;
                this.userData = result.data.data;
                setToken(this.token);

                this.loadData();
            }
            else
                toast.error(result.data.text);
        })
        .catch(error => {
            toast.error(error.response.data.text);
        })
    }

    signUp(_data) {
        const data = {
            user_name: _data.user_name,
            password: _data.password,
            email: _data.email,
            first_name: _data.first_name,
            last_name: _data.last_name
        };
        axios.post(config.api.host_main[NODE_ENV] + config.api.main.sign_up, data)
        .then(result => {
            if (result.data.status) {
                toast.success(result.data.text);
                this.isSignIn = true;
            }
            else
                toast.error(result.data.text);
        })
        .catch(error => {
            toast.error(error.response.data.text);
        })
    }

    setState(_state) {
        this.isSignIn = _state;
    }

    logout() {
        this.token = '';
        removeToken();
    }

    uploadProfileImage(_file) {
        const data = new FormData();
        data.append('file', _file);

        axios.post(config.api.host_main[NODE_ENV] + config.api.main.change_profile_image, data)
        .then(result => {
            if (result.data.status) {
                toast.success(result.data.text);
                this.loadData();
            }
            else
                toast.error(result.data.text);
        })
        .catch(error => {
            toast.error(error.response.data.text);
        })
    }

    update(_data) {
        axios.put(config.api.host_main[NODE_ENV] + config.api.main.update, _data)
        .then(result => {
            if (result.data.status) {
                toast.success(result.data.text);
                this.loadData();
            }
            else
                toast.error(result.data.text);
        })
        .catch(error => {
            toast.error(error.response.data.text);
        })
    }
}

export default new User();