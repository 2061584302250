import EChartsReact from "echarts-for-react";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import styled from "styled-components";
import Button from "./button";

const Component = styled.div`
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem 0;
    width: 100%;
`;

let option = {
    title: {
        text: 'Referer of a Website',
        left: 'center'
    },
    tooltip: {
        trigger: 'item'
    },
    legend: {
        orient: 'vertical',
        left: 'left'
    },
    series: [
        {
            type: 'pie',
            radius: ['30%', '50%'],
            data: [
            ],
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }
    ]
};

export default function Chart(props) {
    const [chartOption, setChartOption] = useState(option);
    const [isFiltered, setIsFiltered] = useState(false);
    const hasLegend = false;//props.legend ?? true;

    useEffect(() => {
        if (props.data !== null) {
            fillData(props.data);
        }
        else {
            let tempOption = structuredClone(chartOption);
            tempOption.title.text = props.title;
            tempOption.title.subtext = '0';
            tempOption.series[0].data = [];
            tempOption.legend = null;
            setChartOption(tempOption);
        }
    }, [props.data])

    function onChartClick(params) {
        if (params.data.children !== undefined && params.data.children !== null) {
            filterChart(params.data, params.data.id);
            setIsFiltered(true);
        }
        else {
            toast.info('В "'+params.data.name+'" нет подкатегорий')
        }
    };

    function filterChart(data) {
        let childrenData = data.children ?? [];
        if (childrenData !== undefined && childrenData !== null) {
            fillData(childrenData);
        }
    }

    function fillData(data) {
        let totalAmount = 0;
        let resultData = [];

        data.forEach(element => {
            if (element.sum !== 0) {
                let am = 0;
                if (element.value !== undefined && element.sum !== element.value) {
                    am = element.sum + element.value;
                }
                else {
                    am = element.sum;
                }
                resultData.push({
                    id: element.id,
                    name: element.name,
                    value: am.toFixed(2),
                    children: element.children ?? null
                });
                totalAmount += Number(am.toFixed(2) ?? 0)
            }
        })

        let tempOption = structuredClone(chartOption);
        tempOption.title.text = props.title;
        tempOption.title.subtext = Number(totalAmount).toFixed(2);
        tempOption.series[0].data = resultData;
        if (!hasLegend) {
            tempOption.legend = null;
        }
        setChartOption(tempOption);
    }

    function cleanFilter() {
        fillData(props.data);
        setIsFiltered(false);
    }

    return <Component>
        <EChartsReact option={chartOption} style={{width: '100%'}} onEvents={{click: onChartClick}}/>
        {   isFiltered && <Button onClick={() => cleanFilter()}>Сбросить</Button>   }
    </Component>
}