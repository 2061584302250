import SVG from '../components/icon';

export default function CurrencyRub (props) {
    return (
        <SVG
            width={props.width}
            height={props.height === undefined || props.height === null ? props.width : props.height}
            style={{margin: 'auto 0'}}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 512 512"
            {...props}
        >
<g>
	<g>
		<path d="M288,320c88.359,0,160-71.633,160-160S376.359,0,288,0c-0.016,0-0.016,0-0.031,0H117.328
			c-5.891,0-10.656,4.773-10.656,10.664v287.992h-32c-5.891,0-10.672,4.781-10.672,10.688C64,315.219,68.781,320,74.672,320h32
			v42.656h-32c-5.891,0-10.672,4.781-10.672,10.688C64,379.219,68.781,384,74.672,384h32v117.344
			c0,5.875,4.766,10.656,10.656,10.656S128,507.219,128,501.344V384h181.328c5.891,0,10.672-4.781,10.672-10.656
			c0-5.906-4.781-10.688-10.672-10.688H128V320H288z M128,21.336h159.969H288c18.734,0,36.891,3.664,53.969,10.883
			c16.516,6.984,31.344,16.984,44.078,29.727c12.75,12.742,22.75,27.578,29.734,44.086C423,123.109,426.672,141.273,426.672,160
			s-3.672,36.891-10.891,53.969c-6.984,16.508-16.984,31.344-29.734,44.086c-12.734,12.742-27.562,22.742-44.078,29.727
			C324.891,295,306.734,298.656,288,298.656H128V21.336z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
        </SVG>
    );
}