import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import styled, { keyframes} from "styled-components";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getCurrencySymbol } from '../../utils';
import Icon from '../../icons';
import * as vars from '../../constants';
import { useEffect, useState } from "react";
import { set, toJS } from "mobx";
import { Device, Paths, Modals } from "../../constants";
import Loading from "../loading";
import { dataStore } from "../../store/data";
import { mainStore } from "../../store/main";
import useIsMobile from '../../hooks/useIsMobile';
 
//#region Styles
const Component = styled.div`
    position: fixed;
    top: 0;
    left: 0;
	flex-direction: column;
	display: flex;
    transform: ${props => props.visible ? 'translateX(0)' : 'translateX(-20vw + 24px)'};
    transition: /*width ease-in .3s, visibility ease-in .1s,*/ transform ease-in .3s;
	width: ${props => props.visible ? '20vw' : 'calc(24px + 1rem)'};
	height: 100%;
	padding: 1rem;
    //visibility: ${props => props.visible ? 'visible' : 'hidden'};

	@media ${Device.mobileM} {
		position: fixed;
		width: 100vw;
		transform: ${props => props.visible ? 'translateX(0)' : 'translateX(-100vw)'};
	}

	@media ${Device.tablet} {
		position: sticky;
		width: ${props => props.visible ? '20vw' : '0'};
		padding: ${props => props.visible ? '1rem' : '0'};
	}

	@media ${Device.laptopS} {
		transform: ${props => props.visible ? 'translateX(0)' : 'translateX(-20vw)'};
	}
`;
const Copyright = styled.span`
	display: inline-block;
	transition: color 0.2s ease-in;
    margin-top: auto;
	height: 2.5rem;
	padding: 0.5rem;
	cursor: pointer;
	text-align: center;
	color: transparent;
	user-select: none;

	&:hover {
		color: white;
	}
`;
const Logo = styled.div`
    flex-shrink: 0;
    display: flex;
    width: 100%;
	//height: 2.5rem;
	padding: .5rem 0;
    color: ${vars.COLOR_WHITE};
	cursor: pointer;
`;
const Menu = styled.div`
    flex-direction: column;
    gap: 1rem;
    display: flex;
	height: calc(100vh - 5rem - 2rem);
	padding-top: 1rem;
    padding-right: 1rem;
	transition: width ease-in .3s;

	@media ${Device.mobileM} {
		padding-right: 0;
	}

	@media ${Device.tablet} {
		padding-right: 1rem;
	}
`;
const MenuItem = styled.div`
	flex-shrink: 0;
    justify-content: center;
	display: flex;
	transition: color ease-in .2s, background-color ease-in .2s;
    border-radius: .5rem;
    width: 100%;
    padding: 0.25rem;
	background-color: ${props => props.active ? vars.COLOR_WHITE : 'rgba(255,255,255,0.2)'};
    cursor: pointer;
	color: ${props => props.active ? vars.COLOR_PRIMARY : vars.COLOR_WHITE};
	user-select: none;

    &:hover {
        background-color: ${vars.COLOR_WHITE};
        color: ${vars.COLOR_TEXT_SECONDARY};
    }
`;
const TotalBalance = styled.div`
	flex-direction: column;
	flex-shrink: 0;
	display: ${props => props.hide ? 'none' : 'flex'};
	//display: none;
	border-radius: .5rem;
	padding: 1rem;
	background-color: rgba(255,255,255,0.2);
	color: ${vars.COLOR_WHITE};
`;
const Balance = styled.span`
	display: ${props => props.hide ? 'none' : 'block'};
	font-size: 1.5rem;
	text-align: center;
	font-weight: 600;
`;
const BalanceTitle = styled.span`
	text-align: center;
	font-size: smaller;
`;
const AccountTitle = styled.span`
	font-size: smaller;
    font-weight: 600;
    color: white;
`;
const AccountList = styled.div`
	flex-direction: column;
	display: flex;
	overflow-y: overlay;
	transition: ease-in .3s height;
	border-radius: .5rem;
	background-color: rgba(255,255,255,1);
`;
const AccountItem = styled.div`
	gap: 1rem;
	display: flex;
	border-top: 1px solid ${vars.COLOR_BORDER};
	padding: 0.5rem 1rem;
	cursor: pointer;

	&:first-child {
		border: none;
	}
`;
const AccountInfo = styled.div`
	flex: 1;
	flex-direction: column;
	justify-content: center;
	display: flex;
`;
const AccountName = styled.span`
	font-weight: 600;
	color: ${vars.COLOR_TEXT_MAIN};
`;
const AccountBalance = styled.span`
	font-size: smaller;
	color: ${props => props.value === 0 ? vars.COLOR_TEXT_SECONDARY : props.value > 0 ? vars.COLOR_SUCCESS : vars.COLOR_DANGER};
`;
const Row = styled.div`
	justify-content: space-between;
	flex-shrink: 0;
	display: flex;
`;
const LogoText = styled.span`
	justify-content: center;
	align-items: center;
	display: flex;
	margin: auto .5rem;
	width: 100%;
	height: 50px;
	text-align: center;
	font-size: 2rem;
    font-weight: 300;
`;
const LoadingSection = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
`;
//#endregion Styles
const IconsNames = [
	'',
	'card',
	'card',
	'bank_deposit',
	'bank_deposit',
	'credit',
	'investment',
	'investment',
	'cash'
]

function Sidebar(props) {
	/** Счета */
	const accounts = dataStore.accounts;
	const [showAccount, setShowAccount] = useState(null);
    const {pathname} = useLocation();
    const navigate = useNavigate();

	const [arv, setarv] = useState(0);
	const [rubValue, setRubValue] = useState(0);
	const [usdValue, setUsdValue] = useState(0);
	const [eurValue, setEurValue] = useState(0);
	const [initCurrentBalance, setInitCurrentBalance] = useState(false);

	const isMobile = useIsMobile();

	useEffect(() => {
		setRubValue(0);
		setUsdValue(0);
		setEurValue(0);
		let rv = 0;
		let uv = 0;
		let ev = 0;

		let init = false;
		accounts.forEach(a => {
			switch (a.currency) {
				case 'rub':
					rv += a.amount;
					break;
				case 'usd':
					uv += a.amount;
					break;
				case 'eur':
					ev += a.amount;
					break;
				default:
					break;
			}
			init = true;
		});

		setRubValue(rv);
			setUsdValue(uv);
			setEurValue(ev);
			setarv(rv);
			if (init)
		setInitCurrentBalance(true);
	}, [accounts]);

		
	function setPage(path) {
		let headerTitle = '';
		switch (path) {
			case Paths.accounts:
				headerTitle = 'Счета';
				break;
			case Paths.counterparties:
				headerTitle = 'Организации';
				break;
			case Paths.items:
				headerTitle = 'Товары';
				break;
			case Paths.tags:
				headerTitle = 'Теги';
				break;
			case Paths.transactions:
				headerTitle = 'Операции';
				break;
			default:
				headerTitle = 'Главная';
				break;
		}
		mainStore.setHeaderTitle(headerTitle);
		if (isMobile) {
			mainStore.setSidebarVisible(false);
		}
		navigate(path);
	}

    return <Component visible={mainStore.sidebarVisible}>
				<Logo onClick={() => navigate('/')}>
					<LogoText>Argent</LogoText>
				</Logo>
                <Menu>
					{ !initCurrentBalance && <LoadingSection><Loading /></LoadingSection> }
					<TotalBalance hide={(rubValue + usdValue + eurValue) === 0}>
						<Balance hide={rubValue === 0}>{rubValue < 0 ? '-' : ''}₽{Math.abs(arv).toFixed(2)}</Balance>
						<Balance hide={usdValue === 0}>{usdValue < 0 ? '-' : ''}${Math.abs(usdValue).toFixed(2)}</Balance>
						<Balance hide={eurValue === 0}>{eurValue < 0 ? '-' : ''}€{Math.abs(eurValue).toFixed(2)}</Balance>
						<BalanceTitle>Текущий баланс</BalanceTitle>
					</TotalBalance>
					<Row>
						<AccountTitle>СЧЕТА</AccountTitle>
						<Icon name='add' width='16px' color='white' onClick={() => mainStore.setModal(Modals.account.name, null)}/>
					</Row>
					<AccountList>
						<AccountItem>
							<Icon name='' width='48px'/>
							<AccountInfo>
								<AccountName onClick={() => navigate('/accounts')}>Все</AccountName>
							</AccountInfo>
							<Icon name={showAccount ? 'arrow_up' : 'arrow_down'} width={(rubValue + usdValue + eurValue) === 0 ? '0px' : '16px'} color={vars.COLOR_TEXT_MAIN} onClick={(event) => {event.preventDefault(); setShowAccount(!showAccount);}}/>
						</AccountItem>
					{
						accounts.filter((account) => showAccount ? account.is_active : false).map(account => 
							<AccountItem key={account.id} onClick={() => mainStore.setModal(Modals.account.name, account)}>
								<Icon name={IconsNames[account.account_type_id]} width='48px' fill={account.amount || 0  > 0 ? 'green' : 'red'}/>
								<AccountInfo>
									<AccountName>{account.name}</AccountName>
									<AccountBalance value={account.amount.toFixed(2) || 0}>{getCurrencySymbol(account.currency)}{(account.amount.toFixed(2) || 0)}</AccountBalance>
								</AccountInfo>
							</AccountItem>
						)
					} 
					</AccountList>
                    <MenuItem onClick={() => setPage(Paths.transactions)} active={pathname === Paths.transactions}>
                        <span>Операции</span>
                    </MenuItem>
                    <MenuItem onClick={() => setPage(Paths.counterparties)} active={pathname === Paths.counterparties}>
                        <span>Организации</span>
                    </MenuItem>
					<MenuItem onClick={() => setPage(Paths.items)} active={pathname === Paths.items}>
                        <span>Товары</span>
                    </MenuItem>
                    <MenuItem onClick={() => setPage(Paths.tags)} active={pathname === Paths.tags}>
                        <span>Теги</span>
                    </MenuItem>
                </Menu>
				<Copyright>by rt</Copyright>
    </Component>
}

export default inject("store")(observer( Sidebar));