import axios from "axios";
import { Config } from "../constants";
import requests from './request';
const NODE_ENV = process.env.NODE_ENV || 'production';


export async function create(transaction) {
    const response = await requests.create(Config.TRANSACTION, transaction);
    return response.data;
}

export async function update(transaction) {
    const response = await requests.update(Config.TRANSACTION, transaction);
    return response.data;
}

export async function remove(id) {
    const response = await requests.remove(Config.TRANSACTION, id);
    return response.data;
}

export default { create, update, remove };