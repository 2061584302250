// Libraries, components, etc. //
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Avatar from '../avatar';
import Button from '../button';
import Input from '../input';
import * as vars from '../../constants';
import { Device } from "../../constants";
import { mainStore } from "../../store/main";

//#region Styles
const ModalComponent = styled.div`
    flex-direction: column;
    gap: 1rem;
    display: flex;
    overflow: auto;
    min-width: ${props => props.wide ? '70vw' : '20vw'};
    max-height: 90vh;
    height: 100%;
    padding: 1rem;

    @media ${Device.mobileM} {
        width: 90vw;
    }

    @media ${Device.tablet} {
        width: auto;
        min-width: ${props => props.wide ? '70vw' : '20vw'};
    }
`;
const FormRow = styled.div`
    flex-direction: row;
    display: flex;

    @media ${Device.mobileM} {
        flex-direction: column;
    }

    @media ${Device.tablet} {
        flex-direction: row;
    }
`;
const Form = styled.div`
    flex: ${props => props.fill ? '1 1' : '0'};
    flex-direction: column;
    gap: 0.5rem;
    display: ${props => props.hidden ? 'none' : 'flex'};
    //padding: .5rem 1rem;
`;
const ButtonRow = styled.div`
    flex-direction: row;
    justify-content: center;
    gap: 0.5rem;
    display: flex;
    padding-bottom: 1rem;
`;
const UserNameText = styled.span`
    width: 100%;
    text-align: center;
`;
const Centered = styled.div`
    flex-direction: column;
    display: flex;
    margin: auto;
`;
const FileUpload = styled.input`
    position: absolute;
    top: auto;
    left: 1rem;
    opacity: 0;
    margin: auto;
    width: 100px;
    height: 100px;
    cursor: pointer;
`;
//#endregion

function ModalProfile(props) {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [profileImageUrl, setProfileImageUrl] = useState('');
    const [userName, setUserName] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [telegramId, setTelegramId] = useState(0);

    const url = props.store.User.userData.profile_image_url;

    useEffect(() => {
        if (props.data !== null) {
            setEmail(props.data.email);
            setFirstName(props.data.first_name);
            setLastName(props.data.last_name);
            setProfileImageUrl(props.data.profile_image_url);
            setUserName(props.data.user_name);
            setTelegramId(props.data.telegram_id);
        }
    }, [props.data]);

    function profileFunction(type = 'none') {
        if (type === 'none')
            return;

        if (email === '' || email === null) {
            toast.error('Email не заполнен');
            return;
        }

        if (firstName === '' || firstName === null) {
            toast.error('Имя не заполнено');
            return;
        }

        if (lastName === '' || lastName === null) {
            toast.error('Фамилия не заполнена');
            return;
        }

        const data = {
            email: email,
            first_name: firstName,
            last_name: lastName,
            telegram_id: telegramId
        }

        switch (type) {
            case 'update':
                props.store.User.update(data);
                break;
            default:
                break;
        }

        mainStore.hideModal();
    }

    return (
        <ModalComponent>
            <FormRow>
                <Form>
                    <Centered>
                        <Avatar firstName={firstName} lastName={lastName} url={url} size='100'/>
                        <FileUpload type='file' id='selectedFile' value={selectedFile} onChange={(event) => props.store.User.uploadProfileImage(event.target.files[0])}/>
                        <UserNameText>@{userName}</UserNameText>
                    </Centered>
                </Form>
                <Form fill={true}>
                    <Input type='text' id='email' label='Email' value={email} onChange={(event) => setEmail(event.target.value)}/>
                    <Input type='text' id='firstName' label='Имя' value={firstName} onChange={(event) => setFirstName(event.target.value)}/>
                    <Input type='text' id='lastName' label='Фамилия' value={lastName} onChange={(event) => setLastName(event.target.value)}/>
                </Form>
            </FormRow>
            <ButtonRow>
                <Button type='success' size='0.9rem' onClick={() => profileFunction('update')}>{'Сохранить'}</Button>
            </ButtonRow>
        </ModalComponent>
    );
}

export default inject("store")(observer(ModalProfile)); 
