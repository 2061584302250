import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import * as vars from '../../constants';
import { IconsNames } from '../../constants';
import Button from '../../components/button';
import Select from '../../components/select';
import Input from '../../components/input';
import Icon from '../../icons';
import { useEffect, useState } from "react";
import {toJS} from 'mobx';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Tree from "../../store/tree";
import ru from 'date-fns/locale/ru';
import Chart from "../../components/chart";
import { Modals } from "../../constants";
import { Device } from "../../constants";
import { dataStore } from "../../store/data";
import { mainStore } from "../../store/main";
import useIsMobile from "../../hooks/useIsMobile";
import Item from "./item";

const Component = styled.div`
    flex-direction: column;
    gap: .5rem;
	flex: 1;
	display: flex;
	border: 1px solid ${vars.COLOR_BORDER};
	border-radius: 1rem;
	padding: 1rem;
	overflow: hidden;
    width: 100%;
	background-color: ${vars.COLOR_WHITE};
`;
const TableOverflow = styled.div`
	flex: 1;
	overflow: auto;
    //display: flex;
    //gap: .5rem;
    //flex-direction: column;
    padding: .5rem 0;
`;
const TransactionType = styled.span`
	//margin: 0 auto;
	border-radius: .25rem;
	padding: .25rem .5rem;
	background-color: ${props => props.backgroundColor};
	font-size: .85rem;
	text-align: center;
	color: ${vars.COLOR_WHITE};
`;
const Account = styled.div`
	flex-direction: row;
	gap: .25rem;
	display: flex; 
`;
const PositionTags = styled.div`
	flex-direction: row;
	gap: .25rem;
	display: flex;
`;
const PositionTag = styled.span`
    border-radius: .25rem;
    padding: .25rem .5rem;
    background-color: ${vars.COLOR_PRIMARY};
    font-size: 0.75rem;
    color: ${vars.COLOR_WHITE};
`;
const EmptyText = styled.span`
	display: ${props => props.hide ? 'none' : 'block'};
	color: ${vars.COLOR_TEXT_SECONDARY};
`;
const FilterSection = styled.div`
	flex-direction: row;
    justify-content: center;
	gap: .5rem;
	display: ${props => props.hide ? 'none' : 'flex'};
    background-color: rgb(61,204,74,0.1);
    border-radius: .5rem;
    padding: .5rem 1rem;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;


    @media ${Device.mobileM} {
        justify-content: center;
    }

    @media ${Device.tablet} {
        justify-content: start;
    }
    //border: 1px solid rgb(61,204,74,0.5);
`;
const DateGroup = styled.span`
    padding: .5rem;
    background-color: rgba(0,0,0,0.05);
    color: rgba(0,0,0,0.7);
    border-top: 1px solid rgba(0,0,0,0.1);
    border-bottom: 1px solid rgba(0,0,0,0.1);
`;
const TransactionGroup = styled.div`
    flex-direction: column;
    display: flex;
    //gap: .25rem;
    
    &:nth-last-child(1) {
        border-bottom: 1px solid  rgba(0,0,0,0.1);
    }
`;
const TransactionItemData = styled.div`
    flex-direction: column;
    display: flex;
    border-bottom: ${props => props.isLast ? 'none' : '1px solid rgba(0,0,0,0.1)'};
    padding-bottom: .25rem;

    &:hover {
        background-color: rgb(61,204,74,0.1);
    }

    
`;
const TransactionData = styled.div`
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: .5rem;
    //justify-content: space-between;
    display: flex;
    width: 100%;
    min-height: 1.75rem;
    padding: .25rem .5rem;

    @media ${vars.Device.mobileS} {
        flex-direction: column;
    }

    @media ${vars.Device.tablet} {
        flex-direction: row;
    }
`;
const TransactionItem = styled.span`
    display: flex;
    justify-content: ${props => props.align || 'center'};
    //white-space: nowrap;
    //flex-direction: row;
    align-items: center;
    gap: .25rem;
    flex: ${props => props.flex !== undefined ? props.flex : '1'};
    width: 100%;
    text-align: ${props => props.align || 'left'};
    min-height: 1.75rem;
`;
const PeriodButton = styled.span`
    display: flex;
    align-items: center;
    padding: .25rem .5rem;
    border-radius: .25rem;
    cursor: pointer;
    color: ${props => props.disabled ? 'grey' : 'black'};

    &:hover {
        background-color: white;
    }
`;
const DateSection = styled.div`
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    gap: .25rem;
`;
const CurrentPeriod = styled.span`
    display: flex;
    align-items: center;
    gap: .25rem;
    text-transform: capitalize;
    user-select: none;
    cursor: pointer;
`;
const DateSelector = styled.div`
    position: absolute;
    z-index: 10;
    //width: 100%;
    //gap: .5rem;
    top: 2rem;
    left: 0;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: .25rem;
    border: 1px solid rgb(61,204,74,0.5);
    
    font-size: smaller;
`;
const DateSelectorText = styled.span`
    user-select: none;
    cursor: pointer;
    text-transform: ${props => props.capitalize ? 'capitalize' : 'none'};
    padding: .5rem 1rem;

    &:hover {
        background-color: rgb(61,204,74,0.1);
    }
`;
const Centered = styled.div`
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    padding: .5rem;
`;
const FilledSection = styled.div`
    flex-direction: row;
    gap: .5rem;
    justify-content: flex-end;
    align-items: center;
    display: flex;
    flex: 1;
    font-size: smaller;
`;
const Charts = styled.div`
    flex-direction: row;
    display: flex;
    width: 100%;

    @media ${Device.mobileM} {
        flex-direction: column;
    }

    @media ${Device.tablet} {
        flex-direction: row;
    }
`;
const ChartExpense = styled.div`
    width: 75%;

    @media ${Device.mobileM} {
        width: 100%;
    }

    @media ${Device.tablet} {
        width: 50%;
    }
`;
const ChartIncome = styled.div`
    overflow: hidden;
    @media ${Device.mobileM} {
        width: 100%;
    }

    @media ${Device.tablet} {
        width: 50%;
    }
`;

const currentDate = new Date();

const buildTree = (items, parent, positions) => {
    parent = parent || null;
    let result = [];

    items.forEach((item) => {
        if (item.parent_id === parent) {
            result.push(item);
            item.positions = positions.filter(p => p?.tags[0]?.id === item.id).re;
            item.children = buildTree(items, item.id, positions);

            if (!item.children.length) {
                delete item.children;
            }
        }
    });
    
    return result;
}


function TransactionsPage(props) {
    const transactions = dataStore.transactions;
    const accounts = dataStore.accounts;

    const isMobile = useIsMobile();
    
    const [firstDay, setFirstDay] = useState(null);
    const [tagValues, setTagValues] = useState(null);
    const reversedTransactions = transactions.slice().reverse();

    const [dateGroups, setDateGroups] = useState([]);
    
    const [page, setPage] = useState(1);
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(100);
    const [accountId, setAccountId] = useState(0);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTag, setSelectedTag] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const [sliceFrom, setSliceFrom] = useState(0);
    const [sliceTo, setSliceTo] = useState(50);
    const [groupedTransactions, setGroupedTransactions] = useState([]);
    const tags = dataStore.tags;
    const [filteredTransactions, setFilteredTransactions] = useState([]);
    const [tagIncomeValues, setTagIncomeValues] = useState(null);
    // dates
    const [periodName, setPeriodName] = useState('');
    const [startDate, setStartDate] = useState(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
    const [endDate, setEndDate] = useState(new Date(currentDate.getFullYear(), currentDate.getMonth()+1, 0));
    const [startDateTemp, setStartDateTemp] = useState(null);
    const [endDateTemp, setEndDateTemp] = useState(null);
    const [showDateSelector, setShowDateSelector] = useState(false);
    const [showDatePeriodSelector, setShowDatePeriodSelector] = useState(false);
    const [init, setInit] = useState(false);
    /** Filter date type
     * 0 - month
     * 1 - day
     * 2 - year
     */
    const [selectionDateType, setSelectionDateType] = useState(0);

    function setDates(start, end) {
        let startD = new Date(start);
        let endD = new Date(end);
        let currentD = currentDate === null ? new Date() : currentDate;
        let lastDayofMonth = new Date(endD.getFullYear(), endD.getMonth()+1, 0);

        startD.setHours(0);
        startD.setMinutes(0);
        startD.setSeconds(0);
        endD.setHours(23);
        endD.setMinutes(59);
        endD.setSeconds(59);

        if (startD.getMonth() === endD.getMonth() && startD.getDate() === 1 && (endD.getDate() === lastDayofMonth.getDate() || endD.getDate() === currentD.getDate())) {
            setStartDate(startD);
            setEndDate(endD);
            setStartDateTemp(startD);
            setEndDateTemp(endD)
            setPeriodName(startD.toLocaleDateString('ru-ru', {month: "long"}))
        }
        else {
            setStartDate(startD);
            setEndDate(endD);
            setStartDateTemp(startD);
            setEndDateTemp(endD)
            setPeriodName(startD.toLocaleDateString('ru-ru')+'-'+endD.toLocaleDateString('ru-ru'))
        }
        setTransactions(startD, endD);
    }

    useEffect(() => {
        let lastDayofMonth = new Date(endDate.getFullYear(), endDate.getMonth()+1, 0);
        if (startDate.getMonth() === endDate.getMonth() && startDate.getDate() === 1 && (endDate.getDate() === lastDayofMonth.getDate() || endDate.getDate() === currentDate.getDate())) {
            setPeriodName(startDate.toLocaleDateString('ru-ru', {month: "long"}))
        }
        else {
            setPeriodName(startDate.toLocaleDateString('ru-ru')+'-'+endDate.toLocaleDateString('ru-ru'))
        }
    }, [startDate, endDate])

    useEffect(() => {
        console.log('run grouping')
        let tempTransactions = [...transactions]
            .filter(t => new Date(t.transaction_datetime) >= startDate && new Date(t.transaction_datetime) <= endDate)
            .filter(t => accountId === 0 || (accountId !== 0 && (t.account_id === accountId || t.accountDestination === accountId)));
        let groups = tempTransactions.reduce((arr, t) => new Set([...arr, new Date(t.transaction_datetime).toLocaleDateString("ru-ru").substring(0,10)]),[]);
        //console.log(groups);
        setDateGroups([...groups]);

        let expenseTree = new Tree(tags, null);
        let incomeTree = new Tree(tags, null);

        let exval = 0;
        let tv = [];
        let tt = [...tempTransactions];

        tv = tempTransactions.reduce((name, tr) => {
            if (tr.transaction_type === 'expense') {
                const { positions } = tr;
                if (positions.length > 0) {
                    positions.forEach(p => {
                        if (p.tags.length > 0) {
                            name[p.tags[0].id] = name[p.tags[0].id] ?? 0;
                                name[p.tags[0].id] = Number(name[p.tags[0].id] + p.amount*p.qty);
                            expenseTree.update(p.tags[0].id, p.amount*p.qty);
                            exval += p.amount*p.qty;
                        }
                        else {
                            console.log(tr, p);
                        }
                    })
                }
            }
            return name;
        }, {});
        expenseTree.summ();
        
        let tiv = [];
        let inval = 0;
        tiv = tt.reduce((name, tr) => {
            if (tr.transaction_type === 'income') {
                const { positions } = tr;
                if (positions.length > 0) {
                    positions.forEach(p => {
                        if (p.tags.length > 0) {
                            name[p.tags[0].id] = name[p.tags[0].id] ?? 0;
                                name[p.tags[0].id] = Number(name[p.tags[0].id] + p.amount);
                                incomeTree.update(p.tags[0].id, p.amount*p.qty);
                        
                                inval += p.amount*p.qty;
                        }
                    })
                }
            }
            return name;
        }, {});
        incomeTree.summ();

        setTagValues(exval !== 0 ? expenseTree.tree : null);
        setTagIncomeValues(inval !== 0 ? incomeTree.tree : null);
        //console.log(expenseTree.tree);

    }, [transactions, startDate, endDate, accountId])

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDateTemp(start)
        setEndDateTemp(end)
        //setDates(start, end);
      };

    function setTransactions(start, end, parent_tag) {
        let cd = null;
        let fd = null;
        if (start === undefined) {
            fd = new Date(startDate);
            cd = new Date(endDate);
        }
        else {
            fd = new Date(start);
            cd = new Date(end);
        }
        fd.setHours(0);
        fd.setMinutes(0);
        fd.setSeconds(0);
        cd.setHours(23);
        cd.setMinutes(59);
        cd.setSeconds(59);

        let isof = fd.toISOString();
        let isoc = cd.toISOString();

        let tempTransactions = transactions
            .filter(t => t.transaction_datetime >= isof && t.transaction_datetime <= isoc)
            .filter(t => accountId === 0 || (accountId !== 0 && (t.account_id === accountId || t.accountDestination === accountId)));
        tempTransactions = tempTransactions.reverse();
        setFilteredTransactions(tempTransactions);

        let ua = [];
        tempTransactions.forEach(t => {
            ua.push(new Date(t.transaction_datetime).toLocaleDateString("ru-ru"));
        })

        let expenseTree = new Tree(tags, null);
        let incomeTree = new Tree(tags, null);

        setGroupedTransactions(tempTransactions.reduce((group, product) => {
            const { transaction_datetime } = product;
            group[new Date(transaction_datetime).toLocaleDateString("ru-ru")] = group[new Date(transaction_datetime).toLocaleDateString("ru-ru")] ?? [];
            group[new Date(transaction_datetime).toLocaleDateString("ru-ru")].push(product);
            return group;
        }, {}))

        let tv = [];
        let exval = 0;
        let tt = [...tempTransactions];
        tv = tempTransactions.reduce((name, tr) => {
            if (tr.transaction_type === 'expense') {
                const { positions } = tr;
                if (positions.length > 0) {
                    positions.forEach(p => {
                        if (p.tags.length > 0) {
                            name[p.tags[0].id] = name[p.tags[0].id] ?? 0;
                                name[p.tags[0].id] = Number(name[p.tags[0].id] + p.amount*p.qty);
                            expenseTree.update(p.tags[0].id, p.amount*p.qty);
                            exval += p.amount*p.qty;
                        }
                    })
                }
            }
            return name;
        }, {});
        expenseTree.summ();
        
        let tiv = [];
        let inval = 0;
        tiv = tt.reduce((name, tr) => {
            if (tr.transaction_type === 'income') {
                const { positions } = tr;
                if (positions.length > 0) {
                    positions.forEach(p => {
                        if (p.tags.length > 0) {
                            name[p.tags[0].id] = name[p.tags[0].id] ?? 0;
                                name[p.tags[0].id] = Number(name[p.tags[0].id] + p.amount);
                                incomeTree.update(p.tags[0].id, p.amount*p.qty);
                        
                                inval += p.amount*p.qty;
                        }
                    })
                }
            }
            return name;
        }, {});
        incomeTree.summ();
        console.log(expenseTree.tree)
        setTagValues(exval !== 0 ? expenseTree.tree : null);
        setTagIncomeValues(inval !== 0 ? incomeTree.tree : null);
        setInit(true);
        
    }

    function navigationFunctions(type = '', size = 0) {
        if (type === '')
            return;

        switch (type) {
            case 'prev':
                if (sliceFrom === 0)
                    break;

                setSliceFrom(sliceFrom - itemsPerPage);
                setSliceTo(sliceTo - itemsPerPage);
                setPage(page - 1);
                break;
            case 'next':
                let toValue = to;
                if (sliceTo + itemsPerPage > size) {
                    toValue = size;
                }
                else {
                    toValue = sliceTo + itemsPerPage;
                }

                setSliceFrom(sliceFrom + itemsPerPage);
                setSliceTo(toValue);
                setPage(page + 1);
                break;
            default:
                break;
        }
    }

    function filterDate(type) {
        let fd = null;
        let ed = null;
        switch (type) {
            // 3 months
            case 1:
                fd = new Date(currentDate.getFullYear(), currentDate.getMonth() - 2, 1);
                ed = new Date(currentDate.getFullYear(), currentDate.getMonth()+1, 0);
                //setDates(fd, ed);
                setStartDate(fd);
                setEndDate(ed);

                setShowDateSelector(false);
                setShowDatePeriodSelector(false);
                setSelectionDateType(1);
                break;
            // Current year
            case 2:
                fd = new Date(currentDate.getFullYear(), 0, 1);
                ed = new Date(currentDate.getFullYear(), currentDate.getMonth()+1, 0);
                //setDates(fd, ed);
                setStartDate(fd);
                setEndDate(ed);

                setShowDateSelector(false);
                setShowDatePeriodSelector(false);
                setSelectionDateType(2);
                break;
            // All time
            case 3:
                fd = new Date(1970, 0, 1);
                ed = new Date(currentDate.getFullYear(), currentDate.getMonth()+1, 0);
                //setDates(fd, ed);
                setStartDate(fd);
                setEndDate(ed);

                setShowDateSelector(false);
                setShowDatePeriodSelector(false);
                setSelectionDateType(2);
                break;
            // Prev
            case 4:
                if (selectionDateType === 0) {
                    fd = new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1);
                    ed = new Date(startDate.getFullYear(), startDate.getMonth(), 0);
                }
                if (selectionDateType === 1) {
                    fd = new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1);
                    ed = new Date(startDate.getFullYear(), startDate.getMonth(), 0);
                }
                if (selectionDateType === 2) {
                    fd = new Date(startDate.getFullYear() - 1, 0, 1);
                    ed = new Date(startDate.getFullYear() , 0, 0);
                }
                //setDates(fd, ed);
                setStartDate(fd);
                setEndDate(ed);

                setShowDateSelector(false);
                setShowDatePeriodSelector(false);
                break;
            // Next
            case 5:
                if (selectionDateType === 0) {
                    fd = new Date(startDate.getFullYear(), startDate.getMonth()+1, 1);
                    ed = new Date(startDate.getFullYear(), startDate.getMonth()+2, 0);
                }
                if (selectionDateType === 1) {
                    fd = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);
                    ed = new Date(startDate.getFullYear(), startDate.getMonth() + 2, 0);
                }
                if (selectionDateType === 2) {
                    fd = new Date(startDate.getFullYear() + 1, 0, 1);
                    ed = new Date(startDate.getFullYear() + 2, 0, 0);
                }
                //setDates(fd, ed);
                setStartDate(fd);
                setEndDate(ed);

                setShowDateSelector(false);
                setShowDatePeriodSelector(false);
                break;
            // Current month
            default:
                fd = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                ed = new Date(currentDate.getFullYear(), currentDate.getMonth()+1, 0);
                //setDates(fd, ed);
                setStartDate(fd);
                setEndDate(ed);

                setShowDateSelector(false);
                setShowDatePeriodSelector(false);
                setSelectionDateType(1);
                break;
        }
    }

    const _onChange = (event) => {
        switch (event.target.id) {
            case 'accountId':
                setAccountId(parseInt(event.target.value));
                break;
            case 'itemsPerPage':
                setItemsPerPage(parseInt(event.target.value));
                setSliceFrom(0);
                setSliceTo(parseInt(event.target.value));
                break;
            default:
                break;
        }
    }

    return <Component>
        <FilterSection>
            <DateSection>
                <PeriodButton  onClick={() => filterDate(4)}><Icon name='arrow_left' width='16px' height='16px'/></PeriodButton>
                <CurrentPeriod onClick={() => {setShowDateSelector(!showDateSelector); setShowDatePeriodSelector(false)}}>
                    <Icon name='calendar' width='24px' height='24px'/>{periodName}
                </CurrentPeriod>
                <PeriodButton onClick={() => filterDate(5)}><Icon name='arrow_right' width='16px' height='16px'/></PeriodButton>
                {
                    showDateSelector && <DateSelector>
                        <DateSelectorText onClick={() => filterDate(0)} capitalize={true}>{currentDate.toLocaleDateString('ru-ru', {month: "long"})}</DateSelectorText>
                        <DateSelectorText onClick={() => filterDate(1)}>3 месяца</DateSelectorText>
                        <DateSelectorText onClick={() => filterDate(2)}>2022 год</DateSelectorText>
                        <DateSelectorText onClick={() => filterDate(3)}>За всё время</DateSelectorText>
                        <DateSelectorText onClick={() => {setShowDateSelector(false); setShowDatePeriodSelector(true)}}>Указать период</DateSelectorText>
                    </DateSelector>
                }
                {
                    showDatePeriodSelector && <DateSelector>
                        <DatePicker
                            selected={startDateTemp}
                            onChange={onChange}
                            startDate={startDateTemp}
                            endDate={endDateTemp}
                            selectsRange
                            inline
                            locale={ru}
                        />
                        <Centered>
                            <DateSelectorText onClick={() => {setStartDate(startDateTemp); setEndDate(endDateTemp); setShowDatePeriodSelector(false)}}>Подтвердить</DateSelectorText>
                        </Centered>
                    </DateSelector>
                }
            </DateSection>
            <div>
                <Select id='accountId' value={accountId} onChange={_onChange}>
                    <option value={0}>Выберите счет</option>
                    {
                        accounts.map((account, index) => <option key={index} value={account.id}>{account.name}</option>)
                    }
                </Select>
            </div>
        </FilterSection>
        <TableOverflow>
        <Charts>
        {
            dateGroups.length > 0 && <ChartExpense>
                <Chart data={tagValues} title='Расходы'/>
            </ChartExpense>
        }
        {
            dateGroups.length > 0 && <ChartIncome>
                <Chart data={tagIncomeValues} title='Доходы' legend={false}/>
            </ChartIncome>
        }
        </Charts>
        {
            dateGroups.map(dateItem => <TransactionGroup>
                <DateGroup>{dateItem}</DateGroup>
                {
                    transactions.filter(t => new Date(t.transaction_datetime).toLocaleDateString("ru-ru").substring(0,10) === dateItem)
                        .filter(t => accountId === 0 || (accountId !== 0 && (t.account_id === accountId || t.accountDestination === accountId)))
                        .map((t, index) => <Item data={t} />)
                }
            </TransactionGroup>)
        }
        {
            dateGroups.length === 0 ? <EmptyText>Тут пока пусто. Стоит исправить!</EmptyText> : null
        }
        </TableOverflow>
    </Component>
}

export default observer(TransactionsPage);