export default class Tree {
    constructor(data, parent) {
        this.tree = this.build(JSON.parse(JSON.stringify(data)), parent);

        this.build = this.build.bind(this);
        this.summ = this.summ.bind(this);
        this.update = this.update.bind(this);
    }

    build(items, parent) {
		parent = parent || null;
		let result = [];
	
		items.forEach((item) => {
			if (item.parent_id === parent) {
				item.children = this.build(items, item.id);
                item.sum = 0;
				if (!item.children.length) {
					delete item.children;
				}
                result.push(item);
			}
		});
        
		return result;
	}

    update(id, value, tree) {
        if (tree === null || tree === undefined) {
            tree = this.tree;
        }
        tree.forEach(element => {
            if (element.id === id) {
                if (element.value === undefined) {
                    element.value = 0;
                }
                element.value = element.value + value;
                return;
            }
            else {
                if (element.children) {
                    this.update(id, value, element.children)
                }
            }
        })
    }

    summ(root) {
        if (root === null || root === undefined) {
            root = this.tree;
            root.forEach(child => {
                root.sum += this.summ(child);
              });
        }
        else {
            if (root.children) {
                root.children.forEach(child => {
                  root.sum += this.summ(child);
                });
            }
            else {
                root.sum = root.value ?? 0;
            }
        }
        
        if (root.value !== undefined) {
            if (root.value === root.sum) {
                return root.sum;    
            }
            else {
                console.log(root);
                return root.sum + root.value;
            }
        }
        else {
            return root.sum;
        }
        
    }
}