import axios from "axios";
import { Config } from "../constants";
import requests from './request';
const NODE_ENV = process.env.NODE_ENV || 'production';

export async function create(data) {
    const response = await requests.create(Config.TAGRELATION, data);
    return response.data;
}

export async function update(position) {
    const response = await requests.update(Config.TAGRELATION, position);
    return response.data;
}

export async function remove(id) {
    const response = await requests.remove(Config.TAGRELATION, id);
    return response.data;
}

export default { create, update, remove };