import axios from 'axios';
import { Config } from '../constants';
const NODE_ENV = process.env.NODE_ENV || 'production';

export function create(type, data) {
    return axios.post(Config.api.host.argent[NODE_ENV]+Config.api[type].create, data);
}

export function update(type, data) {
    return axios.put(Config.api.host.argent[NODE_ENV]+Config.api[type].update, data);
}

export function remove(type, id) {
    return axios.delete(Config.api.host.argent[NODE_ENV]+Config.api[type].delete+id);
}

export default {create, update, remove};