import Icon from '../../icons';
import { IconsNames } from '../../constants';

const TypeIcon = ({type}) => <Icon name={IconsNames[type]}/>

export default function AccountInfo({account, accountDestination}) {

    if (accountDestination === null) {
        return <>
            <TypeIcon type={account.account_type_id} />
            {account.name}
        </>;
    }

    return <>
        <TypeIcon type={account.account_type_id} />
        {account.name}
        <Icon name='arrow_right' />
        <TypeIcon type={accountDestination.account_type_id} />
        {accountDestination.name}
    </>;
    
}