import SVG from '../components/icon';

export default function CurrencyEur (props) {
    return (
        <SVG
            width={props.width}
            height={props.height === undefined || props.height === null ? props.width : props.height}
            style={{margin: 'auto 0'}}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 512 512"
            {...props}
        ><g>
	<g>
		<path d="M432.007,461.986c-1.703,0-3.312,0.406-4.719,1.109h-0.016c-1.156,0.562-117.143,56.875-197.713,6.828
			c-41.876-26-67.454-76.313-76.384-149.924h193.486c5.906,0,10.672-4.781,10.672-10.656c0-5.906-4.766-10.672-10.672-10.672
			H151.098c-1.008-13.531-1.516-27.75-1.516-42.672h207.752c5.891,0,10.672-4.773,10.672-10.664s-4.781-10.665-10.672-10.665
			H149.949c3.344-97.056,30.055-161.791,79.532-192.556c18.211-11.336,39.742-18.102,63.992-20.125
			c19.781-1.656,41.407-0.172,64.298,4.398c39.282,7.844,68.923,22.266,69.501,22.547c0.016,0.008,0.016,0.008,0.016,0.008v-0.008
			c1.422,0.703,3.016,1.109,4.719,1.109c5.891,0,10.656-4.773,10.656-10.664c0-4.196-2.406-7.813-5.938-9.555v-0.008
			c-1.281-0.641-32.109-15.75-74.188-24.235c-24.906-5.022-48.626-6.67-70.454-4.881c-27.766,2.258-52.594,10.086-73.789,23.243
			c-56.04,34.805-86.165,105.642-89.688,210.729h-37.93c-5.891,0-10.672,4.773-10.672,10.665c0,5.891,4.781,10.664,10.672,10.664
			h37.579c0,14.852,0.492,29.079,1.469,42.672h-49.72c-5.891,0-10.664,4.766-10.664,10.672c0,5.875,4.773,10.656,10.664,10.656
			h51.712c9.5,81.782,38.508,138.189,86.587,168.049c21.195,13.172,46.016,20.984,73.781,23.266
			c5.703,0.469,11.531,0.688,17.484,0.688c16.812,0,34.563-1.859,52.97-5.562c42.079-8.484,72.907-23.594,74.188-24.219v-0.016
			c3.516-1.734,5.938-5.359,5.938-9.547C442.663,466.752,437.897,461.986,432.007,461.986z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
        </SVG>
    );
}