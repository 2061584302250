// Libraries, components, etc. //
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Button from '../button';
import Input from '../input';
import Textarea from '../textarea_new';
import Select from '../select';
import * as vars from '../../constants';
import { dataStore } from "../../store/data";
import * as t from '../../adapters/request';
import { mainStore } from "../../store/main";

//#region Styles
const ModalComponent = styled.div`
    width: 100%;
    height: 100%;

`;
const Header = styled.div`
    flex-direction: row;
    display: flex;
    border-bottom: 1px solid ${vars.COLOR_BORDER};
    width: 100%;
    height: 40px;
    text-align: center;
`;
const Title = styled.span`
    margin: auto 1rem;
    width: 100%;
    font-size: 1rem;
    text-align: center;
`;
const FormRow = styled.div`
    flex-direction: row;
    display: flex;
`;
const Form = styled.div`
    flex: ${props => props.fill ? '1 1' : '0'};
    flex-direction: column;
    gap: 0.5rem;
    display: ${props => props.hidden ? 'none' : 'flex'};
    padding: .5rem 1rem;
`;
const ButtonRow = styled.div`
    flex-direction: row;
    justify-content: center;
    gap: 0.5rem;
    display: flex;
    padding-bottom: 1rem;
`;
const StatusButton = styled.div`
    flex-direction: row;
    display: flex;
    overflow: hidden;
    border-radius: .25rem;
`;
const StatusButtonItem = styled.span`
    flex: 1;
    transition: ease-in .3s background-color; 
    padding: .25rem;
    background-color: ${props => props.isActive ? props.color : vars.COLOR_GRAY};
    cursor: pointer;
    font-size: smaller;
    text-align: center;
    color: ${vars.COLOR_WHITE};
    user-select: none;
`;
//#endregion

function ModalAccount(props) {
    const [id, setId] = useState(0);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [isActive, setActive] = useState(true);
    const [currency, setCurrency] = useState('');
    const [accountTypeId, setAccountTypeId] = useState(0);

    const accountTypes = dataStore.accountTypes;

    useEffect(() => {
        if (props.data !== null) {
            setId(props.data.id);
            setName(props.data.name);
            setDescription(props.data.description);
            setActive(props.data.is_active);
            setCurrency(props.data.currency);
            setAccountTypeId(props.data.account_type_id);
        }
    }, [props.data])

    function check() {
        if (name === '' || name === null) {
            toast.error('Название не заполнено');
            return false;
        }

        if (accountTypeId === 0 || accountTypeId === null) {
            toast.error('Не выбран тип счета');
            return false;
        }

        if (currency === '' || currency === null) {
            toast.error('Не выбрана валюта');
            return false;
        }

        return true;
    }

    function createDataObject() {
        return {
            id: id,
            name: name,
            description: description,
            is_active: isActive,
            currency: currency,
            account_type_id: accountTypeId
        };
    }

    async function accountFunction(type = 'none') {
        if (type === 'none')
            return;

        if (!check()) {
            return;
        }

        try {
            let response;
            switch (type) {
                case 'add':
                    response = await t.create(vars.Config.ACCOUNT, createDataObject());
                    break;
                case 'update':
                    response = await t.update(vars.Config.ACCOUNT, createDataObject());
                    break;
                case 'delete':
                    response = await t.remove(vars.Config.ACCOUNT, id);
                    break; 
                default:
                    return;
            }

        }
        catch (error) {
            toast.error(error?.response?.data?.message || error.message);
            return;
        };

        await dataStore.fetch();
        mainStore.hideModal();
    }

    return (
        <ModalComponent>
            <Header>
                <Title>{id === 0 ? 'Добавить' : 'Изменить'} счет</Title>
                <Button type='close' onClick={() => mainStore.hideModal()}/>
            </Header>
            <FormRow>
                <Form fill={true}>
                    <StatusButton>
                        <StatusButtonItem isActive={isActive} color={vars.COLOR_SUCCESS} onClick={() => setActive(!isActive)}>Открыт</StatusButtonItem>
                        <StatusButtonItem isActive={!isActive} color={vars.COLOR_DANGER} onClick={() => setActive(!isActive)}>Закрыт</StatusButtonItem>
                    </StatusButton>
                    <Select id='accountTypeId' value={accountTypeId} disabled={id !== 0} onChange={(event) => setAccountTypeId(event.target.value)}>
                        <option hidden>Выберите тип счета</option>
                    {
                        accountTypes.map((accountType, index) => 
                            <option key={index} value={accountType.id}>{accountType.name_ru}</option>
                        )
                    }
                    </Select>
                    <Select id='currency' value={currency} disabled={id !== 0} onChange={(event) => setCurrency(event.target.value)}>
                        <option hidden>Выберите валюту</option>
                        <option key='rub' value='rub'>Рубль</option>
                        <option key='usd' value='usd'>Доллар</option>
                        <option key='eur' value='eur'>Евро</option>
                    </Select>
                    <Input type='text' id='name' label='Название' value={name} onChange={(event) => setName(event.target.value)}/>
                    <Textarea type='text' id='description' label='Описание' value={description} onChange={(event) => setDescription(event.target.value)}/>
                </Form>
            </FormRow>
            <ButtonRow>
            {
                id !== 0 ?
                    <Button type='danger' size='0.9rem' onClick={() => accountFunction('delete')}>Удалить</Button> : null
            }
               <Button type='success' size='0.9rem' onClick={() => accountFunction(id === 0 ? 'add' : 'update')}>{id === 0 ? 'Добавить' : 'Сохранить'}</Button>
            </ButtonRow>
        </ModalComponent>
    );
}

export default observer(ModalAccount); 
