import axios from "axios";
import { Config } from "../constants";
import requests from './request';
const NODE_ENV = process.env.NODE_ENV || 'production';

export async function getSuggestion(itemId) {
    const response = await axios.get(
        Config.api.host.argent[NODE_ENV]+Config.api[Config.TAG].getsuggestiontag+itemId
    );
    return response.data;
}

export default { getSuggestion };