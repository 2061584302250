// Libraries, components, etc. //
import { action, makeObservable, observable } from "mobx";

class Main {
    sidebarVisible = true;
    modalVisible = false;
    modalName = '';
    modalData = null;
    modalType = 'default';
    headerTitle = '';

    constructor() {
        makeObservable(this, {
            sidebarVisible: observable,
            modalVisible: observable,
            modalName: observable,
            modalType: observable,
            headerTitle: observable,
            setSidebarVisible: action,
            setModal: action,
            hideModal: action,
        });

        this.setSidebarVisible = this.setSidebarVisible.bind(this);
        this.setModal = this.setModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    setSidebarVisible(_state) {
        this.sidebarVisible = _state;
    }

    setHeaderTitle(title) {
        this.headerTitle = title;
    }

    setModal(_name, _data, _type = 'default') {
        this.modalVisible = true;
        this.modalName = _name;
        this.modalData = _data;
        this.modalType = _type;
    }

    hideModal() {
        this.modalVisible = false;
        this.modalName = '';
        this.modalData = null;
        this.modalType = '';
    }
}

const mainStore = new Main();
export { mainStore };