import styled, { keyframes} from "styled-components";
import Icon from "../icons";

const rotate = keyframes`
  	0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const Component = styled.div`
    width: 24px;
    height: 24px;
    animation: ${rotate} 1s linear infinite;
`;

export default function Loading(props) {
    return <Component>
        <Icon name='money' height='24px' width='24px' fill='white' />
    </Component>
}