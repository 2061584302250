export const config = {
    api: {
        host_main: {
            development: "http://localhost:8100/",
            production: "https://tsvetkov.one:8100/"
        },
        host_andtask: {
            development: "http://localhost:8102/",
            production: "https://argent.tsvetkov.one:8102/",
        },
        main: {
            sign_up: "api/auth/signup",
            sign_in: "api/auth/signin",
            get: "api/user",
            update: "api/user",
            change_profile_image: "api/changeprofileimage"
        },
        account_type: "api/accounttypes",
        account: {
            getall: "api/accounts",
            create: "api/account",
            update: "api/account",
            delete: "api/account/"
        },
        conterparty: {
            getall: "api/conterparties",
            create: "api/conterparty",
            update: "api/conterparty",
            delete: "api/conterparty/"
        },
        conterparty_address: {
            getall: "api/conterpartyaddresses",
            create: "api/conterpartyaddress",
            update: "api/conterpartyaddress",
            delete: "api/conterpartyaddress/"
        },
        item: {
            getall: "api/items",
            create: "api/item",
            update: "api/item",
            delete: "api/item/"
        },
        position: {
            getall: "api/positions",
            create: "api/position",
            update: "api/position",
            delete: "api/position/",
        }, 
        tag: {
            getall: "api/tags",
            create: "api/tag",
            update: "api/tag",
            delete: "api/tag/",
            getsuggestiontag: "api/getsuggestiontag/"
        }, 
        transaction: {
            getall: "api/transactions",
            create: "api/transaction",
            update: "api/transaction",
            delete: "api/transaction/",
        },
        tag_relation: {
            getall: "api/tagrelations",
            create: "api/tagrelation",
            update: "api/tagrelation",
            delete: "api/tagrelation/",
        }
    }
}