// Libraries, components, etc. //
import styled from 'styled-components';

const SVGStyle = styled.svg`
    transition: fill 0.2s ease-in;
    fill: ${props => props.color};
    cursor: pointer;

    &:hover {
        fill: ${props => props.hoverColor};
    }
`;

export default function Icon (props) {
    return <SVGStyle color={props.color} hoverColor={props.hoverColor} {...props}>{props.children}</SVGStyle>
}