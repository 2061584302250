// Libraries, components, etc. //
import styled from 'styled-components';
import * as vars from '../constants';

const SelectStyle = styled.select`
    border: 1px solid ${props => props.value !== '' && props.value !== null ? vars.COLOR_SECONDARY : vars.COLOR_BORDER};
    border-radius: 0.25rem;
    width: ${props => props.width ?? '100%'};
    padding: 0.5rem;
    outline: none;
    color: ${props => props.value !== '' && props.value !== null ? vars.COLOR_SECONDARY :vars.COLOR_TEXT_SECONDARY};

    &:focus {
        border: 1px solid ${vars.COLOR_INFO};
        color: ${vars.COLOR_INFO};
    }
`;

export default function Select (props) {
    return <SelectStyle {...props}/>
}