// Libraries, components, etc. //
import { inject, observer } from "mobx-react";
import React from 'react';
import styled from 'styled-components';
import Icon from '../icons';
import * as vars from '../constants';
import { Modals } from "../constants";

//#region Styles
const NodeComponent = styled.div`
    padding-left: ${props => (props.level * 1) + 'rem'};
`;
const TagName = styled.span`
	display: inline-block;
	//border: 1px solid ${vars.COLOR_BORDER};
	//border-radius: .25rem;
	padding: .25rem;
	padding-left: ${props => props.hasChildren ? '.25rem' : 'calc(.25rem + 12px)'};
    cursor: pointer;
`;
//#endregion

const Node = inject("store")(observer(({ item, hasChildren, onToggle, selected, store, level }) => {
	return (
		<NodeComponent level={level}>
			{hasChildren && <Icon name={selected ? 'arrow_down' : 'arrow_right1'} width='12px' color={vars.COLOR_PRIMARY} hoverColor={vars.COLOR_SECONDARY} onClick={onToggle}/>}
            <TagName hasChildren={hasChildren} onClick={() => store.Main.setModal(Modals.tag.name, item)}>{item.name}</TagName>
            <Icon name='add' width='12px' onClick={() => store.Main.setModal(Modals.tag.name, {id: 0, parent_id: item.id})}/>
		</NodeComponent>
	);
}));

export default Node;