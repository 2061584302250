import { useState } from "react";
import styled from "styled-components";
import { Colors } from "../constants";

const InputControl = styled.input`
    position: absolute;
    top: ${props => props.value !== '' ? '20px' : '0'};//${props => props.isFocused ? '20px' : '0px'};
    left: 0;
    height: 35px;
    padding: .5rem;
    transition: top 0.2s ease-out;
    font-size: 13px;
    border: 1px solid ${Colors.secondary};
    border-radius: .25rem;
    outline: none;
    width: 100%;

    &:focus {
        top: 20px;
    }
`;
const Component = styled.div`
    position: relative;
    height: ${props => props.isFocused ? '55px' : '35px'};
    width: 100%;
`;
const Label = styled.span`
    position: relative;
    top: ${props => props.isFocused ? '0' : '.5rem'};
    left: .5rem;
    transition: top 0.2s ease-out, font-weight 0.2s ease-in;
    //font-weight: ${props => props.isFocused ? '600' : '400'};
    //padding: .5rem;
    //padding-top: ${props => props.isFocused ? '0' : '.5rem'};
    height: ${props => props.isFocused ? '20px' : '35px'};
    font-size: 13px;
`;
export default function Input(props) {
    const [isFocused, setFocus] = useState(false);
    return <Component isFocused={isFocused || String(props.value)}>
        <InputControl isFocused={isFocused} {...props} onFocus={() => setFocus(true)} onBlur={() => setFocus(false)}/>
        <Label  isFocused={isFocused || String(props.value)}>{props.label}</Label>
    </Component>
}