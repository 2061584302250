// Libraries, components, etc. //
import styled from 'styled-components';
import { Colors } from '../../constants';
import Modals from './modals';
import { Modals as ModalNames } from '../../constants';
import Button from '../button';

//#region Styles
const Overflow = styled.div`
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    opacity: ${props => props.visible ? '1' : '0'};
    transition: ease-in 0.3s opacity, ease-in 0.3s visibility;
    width: 100vw; 
    height: 100vh;
    background-color: rgba(0,0,0,0.4);
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
`;
const ModalComponentCenter = styled.div`
    position: absolute;
    z-index: 101;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 0.5rem;
    width: auto;
    height: auto;
    
    background-color: ${Colors.white};
`;
const Header = styled.div`
    flex-direction: row;
    display: flex;
    border-bottom: 1px solid ${Colors.border};
    width: 100%;
    height: 40px;
    text-align: center;
`;
const Title = styled.span`
    margin: auto 1rem;
    width: 100%;
    font-size: 1rem;
    text-align: center;
`;
//#endregion

function Modal(props) {
    return (
        <Overflow visible={props.visible} onClick={null}>
            <ModalComponentCenter visible={props.visible}>
                <Header>
                {props.name !== '' && <Title>{ModalNames[props.name].label}</Title>}
                    <Button type='close' onClick={props.onClickClose}/>
                </Header>
                <Modals name={props.name} data={props.data}/>
            </ModalComponentCenter>
        </Overflow>
    );
}

export default Modal; 
