// Libraries, components, etc. //
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled, { useTheme } from 'styled-components';
import Button from '../button';
import Input from '../input';
import Textarea from '../textarea_new';
import * as vars from '../../constants';
import { Device } from "../../constants";
import { dataStore } from "../../store/data";
import { mainStore } from "../../store/main";
import * as t from '../../adapters/request';

//#region Styles
const ModalComponent = styled.div`
    flex-direction: column;
    gap: 1rem;
    display: flex;
    overflow: auto;
    min-width: ${props => props.wide ? '70vw' : '20vw'};
    max-height: 90vh;
    height: 100%;
    padding: 1rem;

    @media ${Device.mobileM} {
        width: 90vw;
    }

    @media ${Device.tablet} {
        width: auto;
        min-width: ${props => props.wide ? '70vw' : '20vw'};
    }
`;
const Header = styled.div`
    flex-direction: row;
    display: flex;
    border-bottom: 1px solid ${vars.COLOR_BORDER};
    width: 100%;
    height: 40px;
    text-align: center;
`;
const Title = styled.span`
    margin: auto 1rem;
    width: 100%;
    font-size: 1rem;
    text-align: center;
`;
const FormRow = styled.div`
    flex-direction: row;
    display: flex;
`;
const Form = styled.div`
    flex: ${props => props.fill ? '1 1' : '0'};
    flex-direction: column;
    gap: 0.5rem;
    display: ${props => props.hidden ? 'none' : 'flex'};
    //padding: .5rem 1rem;
`;
const ButtonRow = styled.div`
    flex-direction: row;
    justify-content: center;
    gap: 0.5rem;
    display: flex;
`;
//#endregion

function ModalItem(props) {
    const [id, setId] = useState(0);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        if (props.data !== null) {
            setId(props.data.id);
            setName(props.data.name);
            setDescription(props.data.description);
        }
    }, [props.data])

    async function itemFunction(type = 'none') {
        if (type === 'none')
            return;

        if (name === '' || name === null) {
            toast.error('Название не заполнено');
            return;
        }

        const data = {
            id: id,
            name: name,
            description: description
        }

        try {
            let response;
            switch (type) {
                case 'add':
                    response = await t.create(vars.Config.ITEM, data);
                    break;
                case 'update':
                    response = await t.update(vars.Config.ITEM, data);
                    break;
                case 'delete':
                    response = await t.remove(vars.Config.ITEM, id);
                    break; 
                default:
                    return;
            }

        }
        catch (error) {
            toast.error(error?.response?.data?.message || error.message);
            return;
        };

        await dataStore.fetch();
        mainStore.hideModal();
    }

    return (
        <ModalComponent>
            <FormRow>
                <Form fill={true}>
                    <Input type='text' id='name' label='Название' value={name} onChange={(event) => setName(event.target.value)}/>
                    <Textarea type='text' id='description' label='Описание' value={description} onChange={(event) => setDescription(event.target.value)}/>
                </Form>
            </FormRow>
            <ButtonRow>
            {
                id !== 0 ?
                    <Button type='danger' size='0.9rem' onClick={() => itemFunction('delete')}>Удалить</Button> : null
            }
                <Button type='success' size='0.9rem' onClick={() => itemFunction(id === 0 ? 'add' : 'update')}>{id === 0 ? 'Добавить' : 'Сохранить'}</Button>
            </ButtonRow>
        </ModalComponent>
    );
}

export default observer(ModalItem); 
