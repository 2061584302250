// Libraries, components, etc. //
import styled, {keyframes} from 'styled-components';
import * as vars from '../constants';
import { useEffect, useState } from 'react';

//#region Styles
const gradient = keyframes`
  	0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
`;
const AvatarStyle = styled.div`
    display: flex;
    animation: ${gradient} 15s ease infinite;
    margin: auto 0;
    border: 1px solid ${vars.COLOR_BORDER};
    border-radius: 50%;
    width: ${props => props.size+'px'};
    height: ${props => props.size+'px'};
    background: linear-gradient(-45deg, #f7bba9, #d1a5be, #92d3eb, #92ebd5);
    background-size: 400% 400%;
`;
const TextStyle = styled.span`
    margin: auto;
    font-weight: 600;
    text-transform: uppercase;
    color: ${vars.COLOR_WHITE};
`;
const Image = styled.img`
    border-radius: 50%;
    width: ${props => 'calc('+props.size+'px - 2px)'};
    height: ${props => 'calc('+props.size+'px - 2px)'};
    object-fit: cover;
`;
//#endregion

export default function Avatar (props) {
    
    const [width, setWidth] = useState(40);
    useEffect(() => {
        if (props.size !== undefined) {
            setWidth(parseInt(props.size));
        }
    }, [props.size])


    if (!Boolean(props.url)) {
        return <AvatarStyle size={width}><TextStyle>{props.placeholder}</TextStyle></AvatarStyle>
    }
    else {
        return <AvatarStyle size={width}><Image src={props.url} size={width}/></AvatarStyle>
    }
}