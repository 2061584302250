// Libraries, components, etc. //
import { inject, observer } from "mobx-react";
import React from 'react';
import styled from 'styled-components';
import Header from '../components/header';
import * as vars from '../constants';
import { dataStore } from "../store/data";

//#region Styles
const Page = styled.div`
	flex-direction: column;
    gap: .5rem;
	flex: 1;
	display: flex;
	border: 1px solid ${vars.COLOR_BORDER};
	border-radius: 1rem;
	padding: 1rem;
	overflow: hidden;
    width: 100%;
	background-color: ${vars.COLOR_WHITE};
`;
const Table = styled.table`
	border-collapse: collapse;
	width: 100%;
	overflow-x: auto;
	table-layout: fixed;
`;
const TableHead = styled.th`
	position: sticky;
	top: 0;
	border-bottom: 1px solid ${vars.COLOR_BORDER};
	padding: .5rem;
	background: ${vars.COLOR_WHITE};
`;
const TableData = styled.td`
	border-bottom: 1px solid ${vars.COLOR_BORDER};
	padding: .5rem 1rem;
`;
const TableOverflow = styled.div`
	flex: 1;
	overflow: auto;
`;
const TableRow = styled.tr`
	&:hover {
		background-color: #ceec98;
		cursor: pointer;
	}
`;
const EmptyText = styled.span`
	display: ${props => props.hide ? 'none' : 'block'};
	color: ${vars.COLOR_TEXT_SECONDARY};
`;
//#endregion

function Conterparty(props) {
	const conterparties = dataStore.conterparties;

	if (conterparties.length === 0)
			return (
				<Page  visible={props.store.Main.sidebarVisible}>
					<EmptyText hide={conterparties.length !== 0}>Тут пока пусто. Стоит исправить!</EmptyText>
				</Page>
			)
		else
			return (
				<Page>
					<TableOverflow>
						<Table>
							<TableRow>
								<TableHead>Название</TableHead>
								<TableHead>Описание</TableHead>
							</TableRow>
						{
							conterparties.map(conterparty => 
								<TableRow onClick={() => {props.store.Main.hideModal(); props.store.Main.setModal('conterparty', conterparty)}}>
									<TableData>{conterparty.name}</TableData>
									<TableData>{conterparty.description}</TableData>
								</TableRow>
							)
						}
						</Table>
					</TableOverflow>
				</Page>
			);
}

export default inject("store")(observer(Conterparty));
