// Libraries, components, etc. //
import axios from 'axios';
import { action, makeObservable, observable } from "mobx";
import { toast } from 'react-toastify';
import { config } from '../env';
import { getToken } from '../utils';

const NODE_ENV = process.env.NODE_ENV || 'production';

class Transaction {
    token = '';
    transactions = [];
    countun = 0;

    constructor() {
        makeObservable(this, {
            token: observable,
            transactions: observable,
            countun: observable,
            create: action,
            update: action,
            delete: action
        });

        this.token = getToken();
        axios.defaults.headers.common['x-user-language'] = 'ru';
        axios.defaults.headers.common['x-app-name'] = 'argent';
        axios.defaults.headers.common['x-access-token'] = this.token;
        this.loadData();

        this.create = this.create.bind(this);
        this.update = this.update.bind(this);
        this.delete = this.delete.bind(this);
    }

    loadData() {
        axios.get(config.api.host_andtask[NODE_ENV]+config.api.transaction.getall)
        .then(result => {
            if (result.data.status) {
                this.transactions = result.data.data;
            }
            else {
                toast.error(result.data.text);
            }
        })
    }

    create(_data) {
        const data = {
            name: _data.transaction.name,
            conterparty_id: _data.transaction.conterparty_id,
            conterparty_address_id: _data.transaction.conterparty_address_id,
            account_id: _data.transaction.account_id,
            account_destination_id: _data.transaction.account_destination_id,
            description: _data.transaction.description,
            total_amount: _data.transaction.total_amount,
            transaction_datetime: _data.transaction.transaction_datetime,
            transaction_type: _data.transaction.transaction_type
        };

        axios.post(config.api.host_andtask[NODE_ENV] + config.api.transaction.create, data)
        .then(result => {
            if (result.data.status) {
                const transactionId = result.data.data.id;
                _data.positions.map(position => {
                    if (position.item_id === 0) {
                        const itemData = {
                            name: position.name,
                            description: ''
                        }

                        axios.post(config.api.host_andtask[NODE_ENV] + config.api.item.create, itemData)
                        .then(itemResult => {
                            const itemId = itemResult.data.data.id;
                            const positionData = {
                                name: position.name,
                                transaction_id: transactionId,
                                item_id: itemId,
                                description: position.description,
                                amount: position.amount,
                                qty: position.qty,
                                total_amount: position.total_amount
                            };
                            axios.post(config.api.host_andtask[NODE_ENV] + config.api.position.create, positionData)
                            .then(positionResult => {
                                const positionId = positionResult.data.data.id;
                                _data.tags.filter(tag => tag.position_id === position.id).map(tag => {
                                    const tagData = {
                                        object_id: positionId,
                                        type: 'position',
                                        tag_id: tag.tag.id
                                    };
                                    axios.post(config.api.host_andtask[NODE_ENV] + config.api.tag_relation.create, tagData)
                                });
                                
                            })
                        })
                    }
                    else {
                        const positionData = {
                            name: position.name,
                            transaction_id: transactionId,
                            item_id: position.item_id,
                            description: position.description,
                            amount: position.amount,
                            qty: position.qty,
                            total_amount: position.total_amount
                        };
                        axios.post(config.api.host_andtask[NODE_ENV] + config.api.position.create, positionData)
                        .then(positionResult => {
                            const positionId = positionResult.data.data.id;
                            _data.tags.filter(tag => tag.position_id === position.id).map(tag => {
                                const tagData = {
                                    object_id: positionId,
                                    type: 'position',
                                    tag_id: tag.tag.id
                                };
                                axios.post(config.api.host_andtask[NODE_ENV] + config.api.tag_relation.create, tagData)
                            });
                            
                        })
                    }
                    
                })
                toast.success(result.data.text);
                this.loadData();
            }
            else {
                toast.error(result.data.text);
            }
        })
        .catch(error => {
            toast.error(error.response.data.text);
        })
    }

    update(_data) {
        const data = {
            id: _data.id,
            name: _data.name,
            conterparty_id: _data.conterparty_id,
            conterparty_address_id: _data.conterparty_address_id,
            account_id: _data.account_id,
            account_destination_id: _data.account_destination_id,
            description: _data.description,
            total_amount: _data.total_amount,
            transaction_datetime: _data.transaction_datetime,
            transaction_type: _data.transaction_type
        };

        axios.put(config.api.host_andtask[NODE_ENV] + config.api.transaction.update, data)
        .then(result => {
            if (result.data.status) {
                toast.success(result.data.text);
                this.loadData();
            }
            else {
                toast.error(result.data.text);
            }
        })
        .catch(error => {
            toast.error(error.response.data.text);
        })
    }

    delete(_id) {
        axios.delete(config.api.host_andtask[NODE_ENV] + config.api.transaction.delete + _id)
        .then(result => {
            if (result.data.status) {
                toast.success(result.data.text);
                this.loadData();
            }
            else
                toast.error(result.data.text);
        })
        .catch(error => {
            toast.error(error.response.data.text);
        })
    }
}

export default new Transaction();